.App {

}


.StudentNavSectionTitle
{
    /* font-family: "Helvetica Neue"; */
    /* font-style: normal; */
    /* font-weight: bold; */
    /* font-size: 38px; */
    /* line-height: 46px; */
    font-size: 30px;
    background: -webkit-linear-gradient(hsla(0, 0%, 88%, 1), hsla(220, 3%, 44%, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.GradientText{
    background: -webkit-linear-gradient(hsla(0, 0%, 88%, 1), hsla(220, 3%, 44%, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.StudentNavSectionContent
{
    margin: 30px 0 ;
}

.btn2 {
    background: linear-gradient(329.31deg, #236685 14.09%, #55B3DE 82.41%);
    border-radius: 25px;
    padding: 15px;
    width: 234px;
    color: #E0E0E0;
    font-size: 16px;
    font-family: var(--font);
    cursor: pointer;
}

:link {
    text-decoration: none;
}