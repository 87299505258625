.title{
    font-size: 18px;
    /* line-height: 33px; */
    text-align: center;
    color: #232D4C;
    padding:5px;
    font-weight: 500;
    margin-bottom: 10px;
}

.container{
    text-align: center;
    padding: 0 20px;
    padding-top: 28px;
}

.buttonsdiv{
    height:52px;
    display:flex;
    width: 100%;
}

.cancelButton{
    background: #FFFFFF;
    border-radius: 0px 0px 0 14px;
    display:inline-block;
    width:100%;
    vertical-align: center;
    border: 1px solid #F4F3F3;
    vertical-align: center;
    display:grid;
    place-items: center;
    text-align: center;
}

.deleteButton{
    background: #FFFFFF;
    border-radius: 0px 0px 14px 0px;
    display:inline-block;
    width:100%;
    vertical-align: center;
    border: 1px solid #F4F3F3;
    vertical-align: center;
    display:grid;
    place-items: center;
    text-align: center;
}

.padder{
    font-size: 13px;
    font-weight: 200;
    width:100%;
    padding: 19px 0 19px 0;
    cursor: pointer;
}

.padder:hover{
    background-color: #eee;
}

