.Slide{
    padding: 26px 31px;
    display: grid;
}

.TwoCol{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 62px;
    padding-right: 114px;
}

.Row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap:10px;
    margin-top:14px;
}

.SkillSlide {
    height:400px;
    display: flex;
    flex-direction: column;
}

.SkillImage{
    height: 200px;
    width: 200px;
    margin:auto;
}

.SkillsDesc{
    color: #232D4C;
    opacity: 0.5;
    font-size: 14px;
    margin-top: -7px;
    margin-bottom: 14px;
}

.SubTitle{
    margin-top: -6px;
    color: #aaa;
}