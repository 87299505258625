.ResumeWrapper {
    width: 80%;
    max-width: 1500px;
    margin: 0px auto;
    margin-bottom: 90px;
    border-radius: 15px;
    overflow: hidden;
    padding:40px 40px 0px 40px;
    background:white;
}

.Main {
    background: #f2f2f3;
    padding: 1px;
}

.Logo {
    height: 25px;
    /* width: 200px; */
}

.Back{
    opacity: 0.5;
    float: left;
}

.Header{
    text-align: center;
    padding:50px;
    margin-top: 10px;
}