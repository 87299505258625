.List{
    background: white;
    border-radius: 10px;
    border: 1px solid #D4D5DA;
    margin:-18px;
    margin-bottom: 13px;
    padding-left:18px;
    padding-right:25px;
    max-height: 130px;
    overflow-y: auto;
}

.List::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
.List::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   

.List::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border-radius: 10px;
  }
  

.List::-webkit-scrollbar-thumb:hover {
    background: #888; 
  }

.Tile{
    padding:12px 0px 15px 0px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D3D5DB;;
}
.Tile:first{
  border: none;
}

.TileTitle{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

}
.TileSub{
    color: #232D4C;
    opacity: 0.7;
    font-weight: 400;
    font-size: 14px;
    line-height: 140.2%;
    margin-top:2px;
}

.Content{
    
}

.Delete{
    flex-shrink:0;
    padding:10px;
    cursor: pointer;
}