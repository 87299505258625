.container{
    width: 393px;
    min-height: 221px;
    background: #FFFFFF;
    border: 1px solid #D3D5DB;
    box-sizing: border-box;
    border-radius: 8px;
    display:inline-block;
    padding: 16px 16px 16px 16px;
    align-self: flex-start;
    /* text-align: center; */
    align-items: center;
}

.Title{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    padding-bottom: 14px;
}

.gridBox{
    display:grid;
    vertical-align: flex-start;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
    padding-bottom: 30px;
}

/* .qualificationcontainer{
    width:50%;
    display:inline-block;
    padding-bottom: 40px;
} */

.degreeTitle{
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #9196A5;
    text-transform: capitalize;
}

.degreeTitleCap{
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #9196A5;
    text-transform: uppercase;
}

.percent{
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    color: #4A90E2;
}
