.NoInvites{
    padding: 40px 60px;
    background: #393F44;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap:10%;
}

.Illus {
    height: 240px;
    flex-shrink: 0;

}

.Content{

}

.Heading{
    font-size: 24px;
    margin-bottom: 0;
    line-height: 1.3;
}

.SubText{
    line-height: 1.3;
    margin-top:10px;
    color: #686B75;
}

.Button {
    background: linear-gradient(329.31deg, #236685 14.09%, #55B3DE 82.41%);
    border-radius: 25px;
    padding: 15px;
    width: 234px;
    color: #E0E0E0;
    font-size: 16px;
    margin-top: 20px;
    font-family: var(--font);
    cursor: pointer;
}