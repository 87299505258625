.Tag {
    padding: 13px 25px;
    background: rgba(210, 28, 28, 0.2);
    border: 1px solid #FF6E6E;
    box-sizing: border-box;
    box-shadow: 0px 0px 44px rgba(255, 0, 0, 0.55), inset 0px 0px 44px rgba(255, 0, 0, 0.25);
    border-radius: 38px;
    display: inline-block;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    /* transition: transform 0.2s ease-in-out; */
    white-space: nowrap;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out ;
}

.Tag:nth-of-type(2n){
    background: rgba(28, 153, 210, 0.2);
    border: 1px solid #6BD0FF;
    box-shadow: 0px 0px 44px rgba(28, 152, 210, 0.55), inset 0px 0px 44px rgba(28, 152, 210, 0.25);
}



.Tag:hover {
    transform: scale(1.05) translateY(-3px);
    box-shadow: 0px 10px 44px -4px rgba(255, 0, 0, 0.50) , inset 0px 0px 33px rgba(255, 0, 0, 0.25);
}
.Tag:nth-of-type(2n):hover{
    box-shadow: 0px 10px 44px -4px rgba(28, 152, 210, 0.5), inset 0px 0px 33px rgba(28, 152, 210, 0.25);
}

.Tag:active{
    transform: scale(1.02) translateY(-2px);
    box-shadow: 0px 0px 20px rgba(255, 0, 0, 0.60), inset 0px 0px 33px rgba(255, 0, 0, 0.25);
}

.Tag:nth-of-type(2n):active{
    box-shadow: 0px 0px 20px rgba(28, 152, 210, 0.60), inset 0px 0px 33px rgba(28, 152, 210, 0.25);
}



.TagContent {
    display: inline-block;
    transition: transform 0.2s ease-in-out;
    

}

.Tag:hover .TagContent{
    /* transform: scale(0.97); */
}


h3 {
    /* font-weight: 800; */
    font-size: 20px;
}

.JobProfilesList {
    margin-top: 20px;
    display: flex;
    gap: 15px 20px;
    flex-wrap: wrap;
}