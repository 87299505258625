.Input{
    background: transparent;
    border-color: transparent;
    padding: 12px 20px;
    color: #e4e4e4;
    line-height: 0.7;
    width: 100%;
    font-family: var(--font);
    /* padding-left: 48px; */
    height: 100%;
}


.Input:focus{
    outline: none;
}

.SearchContainer{
    background: linear-gradient(145.75deg, #1E2024 13.71%, #393F44 65.34%);
    border-radius: 25px;
    /* border: 1px solid #51555B; */
    position: relative;
    /* flex-shrink: 0; */
    width: 100%;
    box-shadow: 0px 0px 0px 1px #51555B ;
    transition: 0.15s box-shadow ease-in-out;
}

.SearchContainer:focus-within{
    box-shadow: 0px 0px 0px 2px #777b80 ;
}

.Icon { 
    height: 18px;
}

.SearchBtn {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(320.09deg, #1E2024 11.25%, #393F44 80.07%, #393F44 100% , #1E2024 300%  );
    border-radius: 50px;
    background-size: 150% 100%;
    height: calc(100% - 10px);
    padding: 0px 20px ;
    cursor: pointer;

    transition: 0.3s background-position ease-in-out;
}

.SearchBtn:hover{
    background-position: 100% 0%;

}


.Suggestions {
    position: absolute;
    top: 110%;
    width: 100%;
    left: 0;
    background:#2D3038;
    border-radius: 30px;
    padding: 5px 20px;
    backdrop-filter: blur(8px);
    border: #666666 1px solid;

}

.Suggestions span{
    padding: 15px 10px;
    display: block;
    border-bottom: solid 1px #B6B6B6 ;
}

.Suggestions span:hover {
    
}

.Suggestions span:last-of-type{
    border-bottom: none;
}  