.filter-container{
  background: #FFFFFF;
  box-shadow: 0px 26px 24px -20px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding: 17px 31px 24px 31px;
  /* font-family: SF Pro Display; */
  font-style: normal;
  /* font-weight: bold; */
  font-size: 20px;
  line-height: 24px;
  color: #232D4C;
  /* display: inline-block; */
  box-sizing: border-box;
  width: 100%;
}

.searchTagInput{
  padding-top: 2px;
  padding-left: 10px;
  font:18x;
  display:inline-block;
  border: 0px;
  display: flex;
  color: #4A90E2;
  min-width: 40%;
  vertical-align: middle;
}

.close-filters-button{
  /* display: inline-block; */
  float: right;
  position: relative;
  /* left: 2px; */
  padding:5px;
  cursor:pointer;
}

.filter-tag-container{
  /* display: inline-block; */
  /* margin-top: 17px; */
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #232D4C;
  /* width: 50%; */
  display: inline-flex;
  box-sizing: border-box;
  flex-direction: column;
}

/* .filter-tag-container:nth-of-type(even){
 padding-left:20px; 
}

.filter-tag-container:nth-of-type(odd){
  padding-right:20px; 
 } */


.filter-input-field{
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #4A90E2;
  box-sizing: border-box;
  border-radius: 5px;
  display: block;
  padding: 11px 20px 12px 16px;
  margin: 8px 0 3px 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4A90E2;
}

.filter-gridBox{
  display:grid;
  grid-template-columns:1fr 1fr;
  grid-gap:17px 40px;
  margin-top:17px;
}

.filter-input-field-multi{
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #4A90E2;
  box-sizing: border-box;
  border-radius: 5px;
  display: block;
  padding: 11px 20px 0 7px;
  margin: 8px 0 3px 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4A90E2;
  min-height: 40px;
}

/* .noHighlight:focus-visible{
  display:none;
} */

.searchTagInput:focus{
  outline:none;
}

.noHightlight{
  padding-top: 2px;
  padding-left: 10px;
  font:18x;
  display:inline-block;
  border: none;
  display: flex;
  color: #4A90E2;
  width: 40%;
}

.apply-filters-button{
  width: 129px;
  height: 50px;
  background: #4A90E2;
  border-radius: 5px;
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.apply-filter-button-div{
  text-align: center;
  margin-top: 25px;
}
