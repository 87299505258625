.StudentsHeader {
    background: #232D4C;
    border-radius: 0px 0px 40px 40px;
    color: white;
    padding: 40px 52px;
    text-align: center;
}

.Title {
    font-size: 36px;
    font-weight: 500;
    margin-top:30px;
    margin-bottom: 5px;
}

.Loading .Title{
    background-color: #e7e7e7;
    opacity: 0.2;
    color:transparent;
    /* height: 41.6px; */
    width: 80%;
    border-radius: 5px;
    max-width: 500px;
    margin-left:auto;
    margin-right: auto;
}

.SubTitle 
{
    font-weight: 500;
    font-size: 26px;
    opacity: 0.5;
}

.Loading .SubTitle{
    background-color: #e7e7e7;
    opacity: 0.2;
    content-visibility: hidden;
    height: 29.6px;
    width: 60%;
    border-radius: 5px;
    max-width: 350px;
    margin-left:auto;
    margin-right: auto;
}


.BackButton {
    position: absolute;
    left: 50px;
}

.RightButtonTray{
    position: absolute;
    right: 50px;
    display: flex;
    gap:8px;
}

.TopRightButton{
    border:1px solid white;
    background: transparent;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    white-space: nowrap;
}