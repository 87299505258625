.leftcontainer{
    display:inline-block;
    height:100vh;
    vertical-align: top;
    width: 380px;
}

.leftimage{
    align-self: bottom;
}

.container{
    width:100vw;
    height:100vh;
    display: grid;
    grid-template-columns: 380px 1fr;
}

.rightcontainer{
    display:flex;
    /* width: 75vw; */
    min-height: 100vh;
    padding: 38px 69px 57px 69px;
    background: #F1F2F3;
    flex-direction: column;
    vertical-align: top;
    height: 100vh;
    overflow-y: auto;
}

.formcontainer{
    padding-right:100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 50px;
}

@media (max-width:1000px) {
    .formcontainer{
        grid-template-columns: 1fr;
    }
    .rightForm{
        margin-top:20px;
    }
}

.uppercontainer{
    padding: 54px;
    display:block;
}

.tellus{
    height: 36px;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #9196A5;
}

.companylogodiv{
    padding: 27px 0 24px 0;
}

.label{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #232D4C;
}

.inputbox{
    width: 776px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D3D5DB;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top:8px;
    padding: 12px;
    font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif ;
}

.inputcontainer{
    padding-bottom: 24px;
    display:inline-block;
    /* margin-right: 21px; */
}

.submitButtoninactive{
    width: 150px;
    height: 50px;
    background: #D3D5DB;
    border-radius: 10px;
    color:white;
    display:block;
}

.submitButton{
    width: 150px;
    height: 50px;
    background: #4A90E2;
    border-radius: 10px;
    color:white;
    display:block;
}

.leftForm{
    display:inline-block;
    /* width: 355px; */
    vertical-align: top;
}
.rightForm{
    /* width: 355px; */
    vertical-align: top;
    float: right;
}

.addmore{
    height: 17px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4A90E2;
    cursor:pointer;
}

.createButton{
    width: 373px;
    height: 51px;
    background: #4A90E2;
    border-radius: 10px;
    color:white;
    margin-top: 44px;
}

.createButtonInactive{
    width: 373px;
    height: 51px;
    background: #D3D5DB;
    border-radius: 10px;
    color:white;
    margin-top: 44px; 
}

.hide{
    display:none;
}

.nooutline:focus{
    outline:none;
}

.logoName{
    /* width: 165px;
    height: 115px; */
    width: 120px;
    height: 120px;
    background: #4A90E2;
    border: 1px dashed #4A90E2;
    box-sizing: border-box;
    border-radius: 12px;
    /* text-align: center; */
    /* vertical-align: center; */
    color: #F1F2F3;
    place-items: center;
    /* padding-top: 31px; */
    display: grid
}

.CreateAccount{
    padding-top: 26px;
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
    color: #0D0C21;
}

.stepsContainer{
    padding-top: 32px;
    padding-left: 17px;
    vertical-align: top;
}

.currentstep{
    display:inline-block;
    padding-left: 12px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #4A90E2;
    vertical-align: top;
}

.incompletestep{
    padding-top:16px;
    padding-left: 12px;
    display:inline-block;
    font-size: 20px;
    line-height: 24px;
    color: #9196A5;
    vertical-align: top;
}

.bottomImage{
    vertical-align: bottom;
    position:absolute;
    bottom:0
}

.completedstep{
    display:inline-block;
    padding-left: 12px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #44D7B6;
    vertical-align: top;
}

.filledLogo{
    text-align: center;
    word-break: break-word;
}

.logopreviewimg{
    /* width: 120px;
    height: 120px; */
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0px 0px 12px 0px #ddbbbb;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.logoinputdiv{
    height: 120px;
    width: 120px;
    border: 1px dashed #4A90E2;
    background-color: #FFFFFF;
    cursor:pointer;  
    place-items: center;
    display: grid;
    border-radius: 12px;
}

.editPencil{
    position: absolute;
    top: 11px;
    left: 100%;
    width: 43px;
    border-radius: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    cursor: pointer;
    padding: 5px;
    transform: translate(-50%, -50%);
    box-shadow: -3px 8px 20px -11px #bdbdbd;
}

.socialContainer{
    display:grid;
    /* grid-template-columns: 1fr 20px; */
}

.logopreviewdiv{
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    display:inline-block;
    position:relative;
    border-radius:'12px';
    object-fit: cover;
}
