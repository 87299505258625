@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
*{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif ;
  --font: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* html { margin-right: calc( 100% - 100vw ); } */
.App {

}


.StudentNavSectionTitle
{
    /* font-family: "Helvetica Neue"; */
    /* font-style: normal; */
    /* font-weight: bold; */
    /* font-size: 38px; */
    /* line-height: 46px; */
    font-size: 30px;
    background: -webkit-linear-gradient(hsla(0, 0%, 88%, 1), hsla(220, 3%, 44%, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.GradientText{
    background: -webkit-linear-gradient(hsla(0, 0%, 88%, 1), hsla(220, 3%, 44%, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.StudentNavSectionContent
{
    margin: 30px 0 ;
}

.btn2 {
    background: linear-gradient(329.31deg, #236685 14.09%, #55B3DE 82.41%);
    border-radius: 25px;
    padding: 15px;
    width: 234px;
    color: #E0E0E0;
    font-size: 16px;
    font-family: var(--font);
    cursor: pointer;
}

:link {
    text-decoration: none;
}
*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

button{
  background-color: transparent;
  border: none;
}

.home-top-bar{
  padding-top: 45px;
  text-align: center;
}

.slider-button:hover{
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.25);
}


.slider-button:active{
  -webkit-filter: brightness(0.95);
          filter: brightness(0.95);
  -webkit-transform: scale(0.99);
          transform: scale(0.99);
}

.slider-button:focus{
  outline: none;
}

.log-out{
  position:absolute;
  background: #FFFFFF;
  border: 1px solid #4A90E2;
  box-sizing: border-box;
  border-radius: 10px;
  width: 127px;
  height: 51px;
}

.job-postings{
  /* font-family: SF Pro Display; */
  margin-top: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 62px;
  text-align: center;
  color: #000000;
}

.middle-container{
  margin-top: 15px;
}

.slide-container{
  display: flex;
  align-items: center;
  max-width: 100%;
  /* justify-content: center; */
  overflow-y: hidden;

}

.single-item{
  text-align: center;
  cursor: pointer;
}

.items-container{
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin: auto;
}

.slide-container::-webkit-scrollbar{
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
.slide-container{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.slider-button{
  cursor: pointer;
  position: fixed;
  z-index: 50;
  background: #FFFFFF;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  width: 65px;
  height: 65px;
  text-align: center;
  vertical-align: center;
  align-items: center;
  transition: box-shadow 0.1s ease-in-out, -webkit-filter 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out, -webkit-filter 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}
@-webkit-keyframes fade-in{
  from{opacity: 0; -webkit-transform: scale(0.8); transform: scale(0.8);}
  to{opacity: 1; -webkit-transform: scale(1); transform: scale(1);}
}
@keyframes fade-in{
  from{opacity: 0; -webkit-transform: scale(0.8); transform: scale(0.8);}
  to{opacity: 1; -webkit-transform: scale(1); transform: scale(1);}
}

.right-arrow{
  right:50px;
}

.left-arrow{
  left:50px;
}








.bottom-container{
  text-align: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 50%;
  bottom: 10%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.hidden{
  display: none;
}

.announcement-text{
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.25;
  background: linear-gradient(270deg, #44D7B6 0%, #3BB0EA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
}

.home-container{
  /* text-align: center; */
  width: 100vw;
  height: 100vh;
  background: radial-gradient(166.67% 269.75% at 85.98% 167.57%, #DCE8F6 0%, #FFFFFF 100%);
}

@media (max-width: 1200px){
  .announcement-text{
    font-size: 3.2rem;
  }
}

.central-loader {
  position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}


.button-enter{
  opacity: 0;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}
.button-enter-active{
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: opacity 0.1s ease-in-out, trasform 0.1s ease-in-out;
}

.button-exit
.button-exit:hover{
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.button-exit-active,
.button-exit-active:hover{
  opacity: 0;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  transition: opacity 0.1s ease-in-out , -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out , transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out , transform 0.1s ease-in-out , -webkit-transform 0.1s ease-in-out;
}

/* .button-exit-done{
  display: none;
} */
.BottomNav_BottomNav__3jHXp {
    position: fixed;
    background-color: #fff;
    border-radius: 10px;;
    /* padding: 10px; */
    box-shadow: 0px 29px 34px -20px rgba(0, 0, 0, 0.25);
    bottom: 40px;
    left:50%;
    overflow: hidden;
    -webkit-transform:translateX(-50%);
            transform:translateX(-50%);
}

.BottomNav_NavLinks__1nMfn{
    display: flex;
}


.BottomNav_ActiveMarker__3UVJ9{
    width: 100px;
    transition: -webkit-transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9);
    transition: transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9);
    transition: transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9), -webkit-transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9);
}

.BottomNav_ActiveMarker__3UVJ9 div{
    border-radius: 10px 10px 10px 10px;
    background: #4A90E2;
    height: 6px;
    width: 100px;
    /* transition: transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9); */
}

/* .ActiveMarker div.Moving{
    animation: skew 0.3s cubic-bezier(0.64, 0, 0.1, 0.9);
} */

.BottomNav_ActiveMarker__3UVJ9 div.BottomNav_MovingLeft__1P_P7 {
    -webkit-transform-origin: right;
            transform-origin: right;
    -webkit-animation: BottomNav_skew__28oNC 0.4s ease-in-out;
            animation: BottomNav_skew__28oNC 0.4s ease-in-out;
}

.BottomNav_ActiveMarker__3UVJ9 div.BottomNav_MovingRight__1RcgS{
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-animation: BottomNav_skew__28oNC 0.4s ease-in-out;
            animation: BottomNav_skew__28oNC 0.4s ease-in-out;
}


@-webkit-keyframes BottomNav_skew__28oNC {
    0%{-webkit-transform: scaleX(1);transform: scaleX(1)}
    50%{-webkit-transform: scaleX(0.5);transform: scaleX(0.5)}
    100%{-webkit-transform: scaleX(1);transform: scaleX(1)}
}


@keyframes BottomNav_skew__28oNC {
    0%{-webkit-transform: scaleX(1);transform: scaleX(1)}
    50%{-webkit-transform: scaleX(0.5);transform: scaleX(0.5)}
    100%{-webkit-transform: scaleX(1);transform: scaleX(1)}
}

.BottomNav_NavLink__2fsNn{
    width: 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding:18px;
    transition: background-color 0.15s ease-in-out;
    cursor: pointer;
}

.BottomNav_NavLink__2fsNn .BottomNav_Icon__1b_66{
    height: 23px;
    -webkit-transform-origin: 50% 120%;
            transform-origin: 50% 120%;
    transition: -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

.BottomNav_NavLink__2fsNn span {
    margin-top:5px;
    font-size:14px;
    color:#9196A5;
}

.BottomNav_NavLink__2fsNn:hover{
    background-color: #f3f3f3;
}

.BottomNav_NavLink__2fsNn:hover .BottomNav_Icon__1b_66{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.BottomNav_NavLink__2fsNn.BottomNav_Active__1jQNK span{
    color:#4A90E2;
}
.Modal_Overlay__3DPNn{
    z-index: 100;
    position: fixed;
    top:0;
    left: 0;
    right:0;
    bottom: 0;
    background: #232D4C;
    opacity: 0.3;
}

.Modal_Modal__siWuN{
  
    z-index: 110;
    /* position: fixed; */
    position: absolute;
    top: 50%;
    background: white;
    border-radius: 10px;
    width: 80%;
    max-width: 900px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.Modal_ModalContainer__ZZQfk{
    top:0;
    left:0;
    z-index: 110;
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

/* .NewScrollBar::-webkit-scrollbar {
    width: 7px;
    height: 7px;
   }
.NewScrollBar::-webkit-scrollbar-thumb {
background: linear-gradient(13deg, #f9d4ff 14%,#c7ceff 64%);
border-radius: 10px;
}
.NewScrollBar::-webkit-scrollbar-thumb:hover{
background: linear-gradient(13deg, #c7ceff 14%,#f9d4ff 64%);
}
.NewScrollBar::-webkit-scrollbar-track{
background: #ffffff;
border-radius: 15px;
box-shadow: inset 17px 0px 0px #f0f0f0;
} */
.Button_Button__2hC6c
{
    border-radius: 10px;
    font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif ;
    font-size: 16px;
    width: 100%;
    padding: 1em 2em;
    cursor: pointer;
    position: relative;
    outline: none;
    border: 1px solid #4A90E2;
    color:#4A90E2;
    background: white;
    box-shadow: 0px 0px 0px 0px #0001;
    transition: box-shadow 0.1s ease-in-out, -webkit-filter 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out, -webkit-filter 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.Button_Primary__3sy1t 
{
    background: #4A90E2;
    color:white;
    border: none;
}

.Button_Disabled__3bZIL{
    color:white;
    background: #D3D5DB;
    border: none;
    cursor: default;
}

.Button_Button__2hC6c:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    box-shadow: 0px 6px 8px 0px #0001;
}

.Button_Button__2hC6c:active{
    -webkit-filter: brightness(0.95);
            filter: brightness(0.95);
    -webkit-transform: scale(0.99);
            transform: scale(0.99);
}

.Button_Button__2hC6c.Button_Disabled__3bZIL:hover{
    -webkit-transform: none;
            transform: none;;
    box-shadow: none;
}

.Button_Loading__2Xpaj{
    cursor: default;
    outline: none;
}


.Button_Loading__2Xpaj::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-animation: Button_swipe__13z0W infinite 1.5s ease-out;
            animation: Button_swipe__13z0W infinite 1.5s ease-out;
    background: #0001;
    -webkit-transform-origin: left;
            transform-origin: left;
}

@-webkit-keyframes Button_swipe__13z0W {
    0% { -webkit-transform: scaleX(0); transform: scaleX(0); opacity: 1;}
    70% {-webkit-transform: scaleX(1);transform: scaleX(1); opacity: 1;}
    100%{ -webkit-transform: scaleX(1); transform: scaleX(1); opacity: 0;}
}

@keyframes Button_swipe__13z0W {
    0% { -webkit-transform: scaleX(0); transform: scaleX(0); opacity: 1;}
    70% {-webkit-transform: scaleX(1);transform: scaleX(1); opacity: 1;}
    100%{ -webkit-transform: scaleX(1); transform: scaleX(1); opacity: 0;}
}
.NewJobForm_Slide__2UX_E{
    padding: 26px 31px;
    display: grid;
}

.NewJobForm_TwoCol__1-QWW{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 62px;
    gap: 0 62px;
    padding-right: 114px;
}

.NewJobForm_Row__1urq0 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap:10px;
    gap:10px;
    margin-top:14px;
}

.NewJobForm_ModalContent__12XfZ {
    background:white;
    padding-bottom:24px;
    border-radius: 0 0 14px 14px;
}

.NewJobForm_ButtonTray__BCWQv{
    display: flex;
    justify-content: center;
    padding: 0 26px;
    grid-gap:20px;
    gap:20px;
}
.Slides_Slide__loVhM{
    padding: 26px 31px;
    display: grid;
}

.Slides_TwoCol__1KZck{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 62px;
    gap: 0 62px;
    padding-right: 114px;
}

.Slides_Row__ZZS_y {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap:10px;
    gap:10px;
    margin-top:14px;
}

.Slides_SkillSlide__2Doj8 {
    height:400px;
    display: flex;
    flex-direction: column;
}

.Slides_SkillImage__2_ZHF{
    height: 200px;
    width: 200px;
    margin:auto;
}

.Slides_SkillsDesc__1sn54{
    color: #232D4C;
    opacity: 0.5;
    font-size: 14px;
    margin-top: -7px;
    margin-bottom: 14px;
}

.Slides_SubTitle__1v03K{
    margin-top: -6px;
    color: #aaa;
}

.Input_InputRow__2Ku_-{
  min-height: 90px;
  padding-bottom: 10px;
}

.Input_InputDiv__1f35P{
  position: relative;
  /* margin-bottom: 17px; */
  background-color: white;
  border: 1px solid #D3D5DB;
  border-radius: 10px;
  display: flex;
  align-items: baseline;
}

.Input_InputDiv__1f35P.Input_ErrorInput__2OONl{
  border-color: #FF6565;
}

.Input_InputElement__acvIW {
  color:#232D4C;
  /* color: #333333; */
  /* font-size: 15px; */
  font-size: 0.9em;
  background-color: transparent;
  font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif ;
  padding: 11px 20px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.1s ease-in;
  padding: 18px 16px;
  padding: 11px 16px;
  /* margin-right:7px; */
}

.Input_InputElement__acvIW:focus {
  border-color: #4A90E2;
  outline: none;
}

.Input_InputLabel__25opt {
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
  color:#232D4C;
}

.Input_RadioBox__3gSEE{
  font-size: 0.9em;
  margin-top:1em;
}
.Input_Option__10d8W{
  color:#232D4C;
  margin-right:20px;
  display: inline-flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}

.Input_Prefix__3fKrs,
.Input_Postfix__2z7HZ
{
  color: #232D4C;
  opacity: 0.3;
  padding:12px
}
.Input_Prefix__3fKrs{
  padding-right: 0;
}
.Input_Postfix__2z7HZ{
  padding-left: 0;
}

.Input_Errors__2zGg5 {
  /* background: #FF6565; */
  /* border-radius: 0px 0px 10px 10px; */
  /* padding: 10px 10px 4px 10px; */
  /* font-size: 0.9em;
  /* margin-top: -10px; 
  color: #FF6565;
  margin-left:10px;
  font-weight: 100; */

  font-size: 0.8em;
  background: #FF6565;
  color: white;
  margin-left: 4px;
  font-weight: 300;
  padding: 2px 6px;
  border-radius: 3px;
}


/* Floating input */
.Input_FloatingInputElement__3fnNo {
  color: #333333;
  font-size: 15px;
  background-color: transparent;
  font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif ;
  padding: 11px 20px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.1s ease-in;
  padding: 18px 16px;
}

.Input_FloatingInputElement__3fnNo:-webkit-autofill {
  background-color: #FAFFBD !important;
  background-image:none !important;
  color: #000000 !important;
}


.Input_FloatingInputLabel__3QSK2 {
  position: absolute;
  transition: background-color 0.15s ease-in-out,
    color 0.1s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out,
    color 0.1s ease-in-out, transform 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out,
    color 0.1s ease-in-out, transform 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
  top: -5px;
  background: white;
  left: 11px;
  padding: 0 5px;
  font-size: 12px;
  color: #959595;
  -webkit-transform-origin: left;
          transform-origin: left;
}

.Input_InputElement__acvIW:-ms-input-placeholder ~ .Input_FloatingInputLabel__3QSK2 {
  color: #959595;
  background-color: transparent;
  transform: translateY(24px) scale(1.25);
  cursor: text;
}

.Input_InputElement__acvIW:placeholder-shown ~ .Input_FloatingInputLabel__3QSK2 {
  color: #959595;
  background-color: transparent;
  -webkit-transform: translateY(24px) scale(1.25);
          transform: translateY(24px) scale(1.25);
  cursor: text;
}

.Input_InputElement__acvIW:focus {
  border-color: #4A90E2;
  outline: none;
}

.Input_InputElement__acvIW:focus ~ .Input_FloatingInputLabel__3QSK2 {
  color:#4A90E2;
}

.Input_InputElement__acvIW:focus:-ms-input-placeholder ~ .Input_FloatingInputLabel__3QSK2 {
  color: #959595;
}

.Input_InputElement__acvIW:focus:placeholder-shown ~ .Input_FloatingInputLabel__3QSK2 {
  color: #959595;
}

.Input_FloatingInputElement__3fnNo::-webkit-input-placeholder {
  opacity: 0;
}

.Input_FloatingInputElement__3fnNo:-ms-input-placeholder {
  opacity: 0;
}

.Input_FloatingInputElement__3fnNo::placeholder {
  opacity: 0;
} 
.Dropdown_Dropdown__ylteP *:focus{
    outline: none;
    background: #0e7dff17;
}

.Dropdown_InputDisplay__1VHUO{
    padding:10px
}

.Dropdown_Dropdown__ylteP{
    position: relative;
    cursor: pointer;
}

.Dropdown_MoreIcon__2uUgR{
    position: absolute;
    right: 7px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    opacity: 0.6;
    width: 20px;
}

.Dropdown_DropdownMenu__2QQEs{
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    /* font-size: 0.9em; */
    z-index: 20;
}
.Dropdown_DropdownMenu__2QQEs::before{
    box-shadow: 0px 4px 15px 0px #0000002b;
    content: "";
    top:0;
    left:0;
    background: white;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #0000001f;
    border-radius: 10px;
}

.Dropdown_DropdownMenuContent__1pEuk{
    position: relative;
    width: 100%;
    height: 100%;
    left:1px;
    top:1px;
    border-radius: 10px;
    overflow: hidden;
}

.Dropdown_DropdownMenuEnter__2d8iH::before{
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform:scaleY(0.5);
            transform:scaleY(0.5);
    opacity: 0;
}
.Dropdown_DropdownMenuEnterActive__1O4ZS::before{
    -webkit-transform:scaleY(1);
            transform:scaleY(1);
    opacity: 1;
    transition: opacity 0.2s ease-in-out, -webkit-transform .2s ease-out;
    transition: transform .2s ease-out, opacity 0.2s ease-in-out ;
    transition: transform .2s ease-out, opacity 0.2s ease-in-out, -webkit-transform .2s ease-out;
}

.Dropdown_DropdownMenuEnter__2d8iH .Dropdown_DropdownMenuContent__1pEuk{
    opacity: 0;
}
.Dropdown_DropdownMenuEnterActive__1O4ZS .Dropdown_DropdownMenuContent__1pEuk{
    opacity: 1;
    transition: opacity .1s ease-in-out;
}



.Dropdown_DropdownMenuExit__2--Tk::before{
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform:scaleY(1);
            transform:scaleY(1);
    opacity: 1;
}
.Dropdown_DropdownMenuExitActive__1cy07::before{
    -webkit-transform:scaleY(0.5);
            transform:scaleY(0.5);
    opacity: 0;
    transition: opacity 0.2s ease-in-out 0.05s, -webkit-transform .2s ease-out 0.05s;
    transition: transform .2s ease-out 0.05s, opacity 0.2s ease-in-out 0.05s ;
    transition: transform .2s ease-out 0.05s, opacity 0.2s ease-in-out 0.05s, -webkit-transform .2s ease-out 0.05s;
}

.Dropdown_DropdownMenuExit__2--Tk .Dropdown_DropdownMenuContent__1pEuk{
    opacity: 1;
}
.Dropdown_DropdownMenuExitActive__1cy07 .Dropdown_DropdownMenuContent__1pEuk{
    opacity: 0;
    transition: opacity .15s ease-in-out;
}


.Dropdown_SearchInput__3aR3W{
    padding:10px 15px;
    border:none;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
}

.Dropdown_SearchIcon__3cIdy{
    position: absolute;
    right: 8px;
    top: 50%;
    -webkit-transform: translateY(-39%);
            transform: translateY(-39%);
    opacity: 0.3;
    height: 19px;
}

.Dropdown_SearchInputDiv__63Ui7{
    position: relative;
}

.Dropdown_OptionList__1p_o9{
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
}

.Dropdown_OptionList__1p_o9::-webkit-scrollbar {
    width: 8px;
  }


.Dropdown_OptionList__1p_o9::-webkit-scrollbar-thumb {
    background: #e4e4e4;
    border-radius: 10px;
  }
  

.Dropdown_List__2bO2I::-webkit-scrollbar-thumb:hover {
    background: #888; 
  }

.Dropdown_Option__1Dyu6{
    padding:12px 15px;
    cursor: pointer;
    display: flex;
    grid-gap:10px;
    gap:10px;
    align-items: center;
    transition: background-color 0.05s ease-in-out;
}
.Dropdown_Option__1Dyu6 input{
    pointer-events: none;
}

.Dropdown_Option__1Dyu6:hover{
    background: #eee;
}

.Dropdown_Option__1Dyu6:active{
    background: #ddd;
}

.Dropdown_Option__1Dyu6.Dropdown_Selected__3k3NO{
    background-color: #4A90E2;
    color: white;
}

.Dropdown_NoOptions__3Gp7z{
    padding: 10px 15px;
    opacity: 0.5;
    display: block;
}
*{
  margin: 0;
  padding: 0;
}

img{
  overflow: hidden;
}

hr{
  height: 0px;
  border: none;
  border-bottom: 1px solid #D3D5DB;
}


.top-bar{

  height: 59px;
  background: #17203A;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
  border-radius: 14px 14px 0px 0px;
}

.cross-button{
  position: relative;
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 20px;
  /* left: 31px; */
  margin-top:20px;
  color: #232D4C;
  background-color: #FF5670;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
}

.x-button{
  margin-left: 0.8px;
  width: 8px;
}

.resume-container{
  /* width: 886px; */
  /* height: 400px; */
  word-break: break-word;
  padding-bottom: 1px;
  background-color: #FFFFFF;
  box-shadow: 0px 26px 24px -20px rgba(0, 0, 0, 0.25);
  border-radius:  0px 0px 14px 14px;
  text-transform: capitalize;
}

.profilepic{
  width: 160px;
  height: 154px;
  box-shadow: 0px 22px 20px -13px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 40px;
}

.profileimg{
  display: block;
  width: 160px;
  height: 154px;
  object-fit: cover;
  object-fit: center;
}

.applicant-info{
  width: 100%;
}

.profile-container{
  display: flex;
  margin: 0px 40px;
  grid-gap: 30px;
  gap: 30px;
  margin-top: 50px;
}

.applicant-name{

  font-weight: bold;
  font-size: 28px;
  color: #232D4C;
}

.applicant-details{
  margin-top: -4px;
  color: #232D4C;
}

.applicant-bio{
  margin-top: 10px;
  color: #9196A5;
  line-height: 1.3em;
  font-weight: 300;
  
}

.rating-box{
  width: 163px;
  height: 50px;
  background: #F4F4F6;
  border-radius: 10px;
  text-align: center;
  display:inline-block;

}

.rating-text{
  /* font-family: SF Pro Display; */
  position: relative;
  top:10px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #4A90E2;
  /* margin-top: 13.5px; */
}


.next-button-container{
  position: absolute;
    top: 0;
    right: -27px;
    height: 100%;
    min-width: 10px;
}

.download-button-container{
  position: absolute;
  /* top: 0; */
  height: 100%;
  /* cursor:pointer; */
  right: -40px;
  min-width: 10px;
}

.prev-button-container{
  position: absolute;
    top: 0;
    left: -27px;
    height: 100%;
    min-width: 10px;
}

.next-button{
  position: sticky;
  width: 54px;
  height: 54px;
  top: 40%;
  background: #27272D;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 7px 23px rgb(0 0 0 / 25%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-button{
  position: sticky;
  width: 54px;
  height: 54px;
  top: 40%;
  background: #27272D;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 7px 23px rgb(0 0 0 / 25%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadButton{
  position: sticky;
  width: 80px;
  height: 80px;
  top: 80%;
  /* background: #27272D; */
  /* border: 1px solid #FFFFFF; */
  /* box-shadow: 0px 7px 23px rgb(0 0 0 / 25%); */
  border-radius: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  cursor:pointer;
  z-index: 2;
}

.prev-button img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  flex-grow: 0;
}

.skills-heading{
  /* font-family: SF Pro Display; */
  font-size: 16px;
  color: #9196A5;
  margin-bottom: 5px;
}

.skills-section{
  margin:50px 40px;
  margin-top: 0px;
}


.skillBox{
  border-radius: 5px;
  display: inline-block;
  padding: 7px 18px;
  font-weight: 300;
  flex-shrink: 0;
}

.skillText{
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  word-break: break-word;
}

.skills-container{
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px 5px;
  gap: 10px 5px;
}
.compact-skills{
  height: 34px;
  display: flex;
  grid-gap:5px;
  gap:5px;
  flex-wrap: wrap;
  overflow: hidden;
}

.section-section{
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

.section-container{
  padding: 50px 60px 60px;
}

.section-title{
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18.5px;
  letter-spacing: -0.01em;
  color: #9196A5;
  padding-bottom: 5px;
}

.project{
  display: inline-block;
  vertical-align: top;
  /* padding: 5px 15px 15px 0; */
}

.experience-pic {
  /* width: 35px;
  height: 35px; */  /* opacity: 0.2; */
  border-radius: 4px;
  display: inline-block;
  align-items: flex-start;
  padding: 8px;
}

.project-name-flexbox{
  display:flex;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
}

.project-name{
  position: relative;
  top: -2px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  /* word-break: break-word; */
  /* word-break: break-all; */
}

.project-title{
/* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18.5px;
  color: #000000;
  /* max-width: 230px; */
  overflow-wrap: break-word;
  /* word-break: break-all; */
}

.project-date{

  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #969BA9;
  text-transform: capitalize;
  margin-top: 3px;
  /* overflow-wrap: break-word; */
  /* word-break: break-all; */
}

.project-description{
  font-size: 15px;
  line-height: 20px;
  margin-top: 10px;
  color: #9196A5;
  font-weight: 300;
  /* overflow-wrap: break-word; */
  /* word-break: break-all; */

}

.visit-link-button{
  background: #FFFFFF;
  border: 1px solid #4A90E2;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  margin-left: 14px;
  padding: 6px 10px;
  flex-shrink: 0;
}

.title-date-div{
  display: inline-block;
  vertical-align: top;
  padding-top:2px;
}

.visit-text{

display: block;
font-size: 14px;
letter-spacing: -0.01em;
color: #4A90E2;
line-height: 1em;
}

.gold{
  background-color: #ffef99;
  color: #E09320;
}

.silver{
  background-color: #ececed;
  color: #898989;
}

.bronze{
  background-color: #e7d2c1;
  color: #AF6E38;
}

.no-level{
  border: 1px solid #D4D5DA;
  color: #000;
}

.loading{

  height: 27px;
  width: 83px;
  background-color: #e7e7e7;
}

.tagRemoverButton{
  margin-left: 13px;
}

.project-pic{
  width:25px;
}

/* Print Stuff */

.contact-container{
  padding-top:8px;
  display:none;
}

.nobreakOnPrint{
  page-break-inside: avoid;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
}

.dontBreakAfter{
  page-break-after: avoid;
  page-break-after: avoid;
  -webkit-column-break-after: avoid;
          break-after: avoid;
}

.dontBreakBefore{
  page-break-before: avoid;
  page-break-before: avoid;
  -webkit-column-break-before: avoid;
          break-before: avoid;
}

.status{
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  min-width: 200px;
}
.status.Hired,
.status-message.Hired{
 color:#57a3c8;
 background-color:#e6f3fb;
}

.status.Rejected,
.status-message.Rejected
{
  color:#ff4a4a;
  background-color:#ffeeef;
 }

 .status-message{
   text-transform: none;
   align-items: center;
   font-size: 16px;
   color:#444;
   background: #f5f5f5;
   padding: 20px 30px;
   text-align: center;
   border-radius: 5px;
 }

.side-button-enter .button{
  opacity: 0;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}
.side-button-enter-active .button{
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: opacity 0.1s ease-in-out, trasform 0.1s ease-in-out;
}

.side-button-exit .button,
.side-button-exit:hover .button{
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.side-button-exit-active .button,
.side-button-exit-active:hover .button{
  opacity: 0;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  transition: opacity 0.1s ease-in-out , -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out , transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out , transform 0.1s ease-in-out , -webkit-transform 0.1s ease-in-out;
}

/* Loading Styles */

.profile-container.Loading .applicant-name::after,
.profile-container.Loading .applicant-details::after,
.profile-container.Loading .applicant-bio::after,
.project.Loading .experience-pic::after,
.project.Loading .project-title::after,
.project.Loading .project-date:after,
.project.Loading .project-description:after
{
  position: absolute;
  top:0;
  left:0;
  content: "";
  border-radius: 5px;
  display: block;
  height: 80%;
  width: 50%;
  background: #e5e5e5;
}
.profile-container.Loading .applicant-details::after,
.project.Loading .project-date:after{
  width: 30%;
  height: 60%;
}
.profile-container.Loading .applicant-bio::after{
  width: 100%;
  height: 80%;
}

.project.Loading .experience-pic:after,
.project.Loading .project-description::after,
.project.Loading .project-title::after
{
  width: 100%;
  height: 100%; 
}

.profile-container.Loading .applicant-name,
.profile-container.Loading .applicant-details,
.profile-container.Loading .applicant-bio,
.project.Loading .experience-pic,
.project.Loading .project-title,
.project.Loading .project-date,
.project.Loading .project-description
{
  position: relative;
  color:transparent;
}

/* .project.Loading .experience-pic{


} */

@media print {
  .contact-container{
    display: block;
  }

  .hideOnPrint, .top-bar, .candididateTaggerDiv {
    display:none;
  }

  .resume-container{
    box-shadow: none
  }

  .section-title{
    font-size: 18px;
    color:#4A90E2;
    /* font-weight: 600; */
  }

  .project-pic{
    width: 15px;
  }

  .project-title, .project-date, .skillText{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000
  }

  .project-title{
    font-weight: 500;
    font-size: 14px;
  }

  .project-description{
    font-size: 10px;
    line-height: 11px;
    color: #000;
  }

  /* .skillText{
    font-size: 12px;
  } */

  .section-container{
    padding-top: 0;
    padding-bottom: 40px;
  }

  .applicant-bio{
    margin:0
  }

  .section-section{
    /* grid-gap: 20px 10px; */
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .skillBox{
    padding: 7px 12px;
  }
}
.filter-container{
  background: #FFFFFF;
  box-shadow: 0px 26px 24px -20px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding: 17px 31px 24px 31px;
  /* font-family: SF Pro Display; */
  font-style: normal;
  /* font-weight: bold; */
  font-size: 20px;
  line-height: 24px;
  color: #232D4C;
  /* display: inline-block; */
  box-sizing: border-box;
  width: 100%;
}

.searchTagInput{
  padding-top: 2px;
  padding-left: 10px;
  font:18x;
  display:inline-block;
  border: 0px;
  display: flex;
  color: #4A90E2;
  min-width: 40%;
  vertical-align: middle;
}

.close-filters-button{
  /* display: inline-block; */
  float: right;
  position: relative;
  /* left: 2px; */
  padding:5px;
  cursor:pointer;
}

.filter-tag-container{
  /* display: inline-block; */
  /* margin-top: 17px; */
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #232D4C;
  /* width: 50%; */
  display: inline-flex;
  box-sizing: border-box;
  flex-direction: column;
}

/* .filter-tag-container:nth-of-type(even){
 padding-left:20px; 
}

.filter-tag-container:nth-of-type(odd){
  padding-right:20px; 
 } */


.filter-input-field{
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #4A90E2;
  box-sizing: border-box;
  border-radius: 5px;
  display: block;
  padding: 11px 20px 12px 16px;
  margin: 8px 0 3px 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4A90E2;
}

.filter-gridBox{
  display:grid;
  grid-template-columns:1fr 1fr;
  grid-gap:17px 40px;
  margin-top:17px;
}

.filter-input-field-multi{
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #4A90E2;
  box-sizing: border-box;
  border-radius: 5px;
  display: block;
  padding: 11px 20px 0 7px;
  margin: 8px 0 3px 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4A90E2;
  min-height: 40px;
}

/* .noHighlight:focus-visible{
  display:none;
} */

.searchTagInput:focus{
  outline:none;
}

.noHightlight{
  padding-top: 2px;
  padding-left: 10px;
  font:18x;
  display:inline-block;
  border: none;
  display: flex;
  color: #4A90E2;
  width: 40%;
}

.apply-filters-button{
  width: 129px;
  height: 50px;
  background: #4A90E2;
  border-radius: 5px;
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.apply-filter-button-div{
  text-align: center;
  margin-top: 25px;
}

.SkillInput_SkillTextInput__2nsHZ{
    padding: 15px 11px;
    width: 100%;
    border:none;
    outline: none;
}

.SkillInput_SkillInput__2HfU_{
    display: flex;
    border: 1px solid #9196A5;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
}

.SkillInput_AddBtn__fPoGp{
    padding:9px;
    background: #D3D5DB;
    border-radius: 5px;
    margin-right: 7px;
}

.SkillInput_AddBtn__fPoGp.SkillInput_Active__3knu9{
    cursor: pointer;
    background: #4A90E2;
}

.SkillInput_AddBtn__fPoGp img{
    display: block;
}

.SkillInput_SkillList__1uevH{
    display: flex;
    flex-wrap: wrap;
    grid-gap:5px;
    gap:5px;
    margin: 8px 0;
    max-height: 100px;
    overflow: auto;
}
.Skill_Skill__24wlY{
    background: #D3D5DB;
    padding: 4px 9px 4px 11px;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    display: inline-flex;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
}

.Skill_RemoveBtn__SPCgK img{
    display: block;
}

.Skill_RemoveBtn__SPCgK{
    margin-left: 7px;
    cursor: pointer;
    outline: none;
}
.SkillSuggestions_SuggestionContainer__1gbOZ{
    height: 100px;
    overflow: auto;
    margin-bottom: 5px;
}

.SkillSuggestions_SuggestionTitle__ozCvl{
    margin-top: -10px;
    font-size: 12px;
    opacity: 0.5;
}


.SkillSuggestions_Skill__1mqRW{
    cursor: pointer;
    font-size: 12px;
}

.SkillSuggestions_SkillNotSelected__2Uk8x{
    background: #eee;
}

.SkillSuggestions_SkillSelected__2ie8E{
    background: #D3D5DB;
}
.QualInput_QualInput__3Nx3d{
    background: #F1F2F3;
    border-radius: 8px;
    padding: 18px;
    min-height: 300px;
}

.QualInput_FirstRow__1Nbkf{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap:10px;
    gap:10px;
    margin-top: 14px;
}

.QualInput_SecondRow__1aJKc{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap:10px;
    gap:10px;

}
.CoursesList_List__mUJUh{
    background: white;
    border-radius: 10px;
    border: 1px solid #D4D5DA;
    margin:-18px;
    margin-bottom: 13px;
    padding-left:18px;
    padding-right:25px;
    max-height: 130px;
    overflow-y: auto;
}

.CoursesList_List__mUJUh::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
.CoursesList_List__mUJUh::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   

.CoursesList_List__mUJUh::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border-radius: 10px;
  }
  

.CoursesList_List__mUJUh::-webkit-scrollbar-thumb:hover {
    background: #888; 
  }

.CoursesList_Tile__MaDDX{
    padding:12px 0px 15px 0px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D3D5DB;;
}
.CoursesList_Tile__MaDDX:first{
  border: none;
}

.CoursesList_TileTitle__L0djZ{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

}
.CoursesList_TileSub__qT-IX{
    color: #232D4C;
    opacity: 0.7;
    font-weight: 400;
    font-size: 14px;
    line-height: 140.2%;
    margin-top:2px;
}

.CoursesList_Content__27oHw{
    
}

.CoursesList_Delete__3d-LQ{
    flex-shrink:0;
    padding:10px;
    cursor: pointer;
}
.QualPresets_QualPresets__2BEY-{
    max-width: 400px;
    margin: auto;
    width: 100%;
    margin-top:30px;
}

.QualPresets_PresetHeader__3Pr9n {
    font-size: 14px;
    color:#AAAAAA;
}

.QualPresets_Preset__2zjti{
    width: 100%;
    cursor: pointer;
    background: #e9e9e9;
    border-radius: 4px;
    padding: 18px 22px;
    transition: background-color 0.1s ease-in-out;
    margin-bottom: 10px;

}

.QualPresets_Preset__2zjti .QualPresets_Title__Wee1t {
    color: #4A90E2;
    font-weight: 500;
}

.QualPresets_Preset__2zjti .QualPresets_Subtitle__2-pxf {
    color : #919191;
    font-size: 14px;
    margin-top: -4px
}

.QualPresets_Preset__2zjti:hover {
    background-color: #DEF2FB;
}

.QualPresets_Preset__2zjti:active {
    background-color: #D0ECF9;
}

.QualPresets_PresetSelected__2z_4S {
    background: #4A90E2;
}
.QualPresets_PresetSelected__2z_4S .QualPresets_Title__Wee1t{
    color: white
}

.QualPresets_PresetSelected__2z_4S .QualPresets_Subtitle__2-pxf{
    color: rgba(255, 255, 255, 0.59);
}

.QualPresets_PresetSelected__2z_4S:hover{
    background: #6AA3E7;
}


.QualPresets_PresetList__1OxGu {
    overflow: auto;
    height: 300px;
    padding-right: 10px;
}


.QualPresets_NoPresets__1qj0T {
    height: 100%;
    width: 100%;
    background: #f7f7f7;
    border-radius: 4px;
    color: #aaaa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}
.HomeInfo_container__2O_7C{
    background: #FFFFFF;
    box-shadow: 0px 26px 24px -20px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    text-align: left;
    padding-bottom: 25px;
}

.HomeInfo_header__2E8kg{
    background: linear-gradient(203.68deg, #E071F2 6.87%, #FE8999 98.3%);
    box-sizing: border-box;
    border-radius: 14px;
    height: 136px;
    padding: 18px;
    background-image: url(/static/media/headerBackgroundimage.4a8a599a.svg);
    text-align: left;
    vertical-align: middle;
}

.HomeInfo_headerInner__2D3mO{
    background: rgba(255, 255, 255, 0.45);
    -webkit-backdrop-filter: blur(44px);
            backdrop-filter: blur(44px);
    border-radius: 8px;
    padding: 19px 22px 25px 17px;
    text-align: left;
    vertical-align: middle;
    display:grid;
    grid-template-columns: 1fr auto;
}

.HomeInfo_buttonsContainer__3n8OT{
    text-align:center;
    vertical-align:middle;
    display: grid;
    grid-gap:16px;
    /* grid-template-columns:150px 1fr; */
    /* min-width:310px; */
    float:right;
}

.HomeInfo_title__1SSuZ{
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    max-height: 33px;
    overflow:hidden;
    color: #232D4C;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.HomeInfo_titleBox__1LuQ1{
    display:flex;
    max-width: 370px;
    overflow:hidden;
    flex-direction: column;
}

.HomeInfo_desc__3mNIy{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    margin-top: 4px;
    text-transform: capitalize;
    max-height: 34px;
    overflow:hidden;
}    

.HomeInfo_appliedBox__1Fw6O{
    padding: 9px 14px 9px 11px;
    height: 35px;
    background: #FFFFFF;
    border-radius: 12px;
    /* float: right; */
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0075FF;
    vertical-align:middle;
    cursor:pointer;
}

.HomeInfo_deletejobBox__2Cusj{
    padding: 9px 28px 9px 28px;
    height: 35px;
    background: #FFFFFF;
    border-radius: 12px;
    /* float: right; */
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FF5670;
    vertical-align:middle;
    margin-left:16px;
    cursor:pointer;
}

.HomeInfo_secondaryBox__UcBpm{
    padding: 16px 18px 18px 18px;
}

.HomeInfo_categoryBox__2zo1p{
    max-width: 804px;
    /* height: 69px; */
    background: #F4F4F6;
    border-radius: 8px;
    padding: 14px 16px 14px 16px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.HomeInfo_catTitle__18vC0{
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    opacity: 0.5;
    margin-bottom: 4px;
}

.HomeInfo_catBox__1PBo5{
    margin-right: 57px;
    /* display:inline-block; */
}

.HomeInfo_cat__1cJFX{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    margin-top: 4px;
}

.HomeInfo_thirdBox__2T6WQ{
    text-align: left;
    padding-left: 18px;
    padding-right: 18px;
    vertical-align: top;
    display:grid;
    grid-template-columns: 393px 1fr;
}

.HomeInfo_jobdesc__1LG3u{
    min-height: 221px;
    vertical-align:top;
    padding-left: 18px;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    word-break: break-word;
}

.HomeInfo_descpara__ZJdRG{
    font-size: 14px;
    line-height: 17px;
    word-break: break-word;
    color: #232D4C;
    padding-right: 20px;
}

.HomeInfo_list__3hSOj{
    padding-left: 18px;
    padding-top: 4px;
}

.HomeInfo_schedule__lBUlF{
    padding-top: 18px;

}

.HomeInfo_Qualifications__rJBOp{
    margin-top:20px;
    padding: 0 20px;
    margin-bottom: 20px;
}

.HomeInfo_Qual__1_Zuh{
    margin-bottom: 10px;
    text-transform: capitalize;
}
.HomeInfo_College__18Lv-{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 2px;
}

.HomeInfo_CourseList__EPdex{
    font-size: 14px;
    line-height: 140.2%;
}
.minimumqualifications_container__2QLm0{
    width: 393px;
    min-height: 221px;
    background: #FFFFFF;
    border: 1px solid #D3D5DB;
    box-sizing: border-box;
    border-radius: 8px;
    display:inline-block;
    padding: 16px 16px 16px 16px;
    align-self: flex-start;
    /* text-align: center; */
    align-items: center;
}

.minimumqualifications_Title__1fP1Y{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    padding-bottom: 14px;
}

.minimumqualifications_gridBox__39lf2{
    display:grid;
    vertical-align: flex-start;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
    padding-bottom: 30px;
}

/* .qualificationcontainer{
    width:50%;
    display:inline-block;
    padding-bottom: 40px;
} */

.minimumqualifications_degreeTitle__nLyAd{
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #9196A5;
    text-transform: capitalize;
}

.minimumqualifications_degreeTitleCap__TZvCK{
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #9196A5;
    text-transform: uppercase;
}

.minimumqualifications_percent__3tB9b{
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    color: #4A90E2;
}

.Card_CardContainer__LtlC0{
  position: relative;
  margin: 15px;
  padding-top: 32px;
}

.Card_Card__3XtoD {
  display: flex;
  flex-direction: column;
  width: 363px;
  padding: 22px 18px;
  background: white;
  box-sizing: border-box;
  border-radius: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  grid-gap: 12px;
  grid-gap: 12px;
  gap: 12px;
  z-index: 10;
  position: relative;
  
}

.Card_NewCard__2-rko{
    border: 3.5px dashed #4A90E2;
    background: transparent;
    /* border-radius: 10px; */
    color: #4A90E2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: -webkit-transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    min-height: 362px;
}

.Card_NewCard__2-rko:hover{
  -webkit-transform:scale(1.01);
          transform:scale(1.01);
}

.Card_NewCardContent__BHCGq{
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}
.Card_NewCard__2-rko:hover .Card_NewCardContent__BHCGq
{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.Card_NewCard__2-rko:active .Card_NewCardContent__BHCGq
{
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.Card_NewCard__2-rko p {
    font-size: 18px;
}
.Card_Plus__2Y0Bw{
    font-size: 60px;
}
.Card_Card__3XtoD a{
    color: #232d4c;
}

.Card_Container__cJ1TF{
    background: rgba(255, 255, 255, 0.45);
    -webkit-backdrop-filter: blur(44px);
            backdrop-filter: blur(44px);
    border-radius: 15px;
    min-height: 50px;
    padding:15px;
    color: #232d4c;
    cursor: pointer;
    border: 2px solid #fff0;  
    transition: border-color 0.15s ease-in-out, box-shadow 0.1s ease-in-out, background-color 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;  
    transition: border-color 0.15s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out, background-color 0.1s ease-in-out;  
    transition: border-color 0.15s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out, background-color 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.Card_Container__cJ1TF:hover.Card_Active__2HteB{
  background-color:#ffffff8c;
  /* border-color: #ffffff57;; */
  box-shadow: 1px 1px 5px #ffffff4f;
  transition: border-color 0.15s ease-in-out, box-shadow 0.1s ease-in-out background-color 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: border-color 0.15s ease-in-out, transform 0.1s ease-in-out,  box-shadow 0.1s ease-in-out background-color 0.1s ease-in-out;
  transition: border-color 0.15s ease-in-out, transform 0.1s ease-in-out,  box-shadow 0.1s ease-in-out background-color 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.Card_Container__cJ1TF:active.Card_Active__2HteB{
  -webkit-transform:scale(0.98);
          transform:scale(0.98);
}


.Card_SingleContainer__1t38E{
    display: flex;
    align-items: center;
    /* cursor: pointer; */
}

.Card_SingleContainer__1t38E span {
    width: 100%;
}

.Card_CardContentContainer__1pNbe {
  min-height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  position: relative;
}

.Card_JobTitleDiv__2AGZf{
    display: flex;
}

.Card_JobTitle__Ak2ny {
  font-size: 28px;
  line-height: 33px;
  color: #232d4c;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.Card_JobDetails__1ZFGN {
  /* position: absolute; */
  /* width: 193px; */
  /* height: 17px; */
  /* left: 18px; */
  /* top: 56px; */
  /* font-family: SF Pro Display; */
  /* font-style: normal; */
  font-weight: 400;
  font-size: 14px;
  height: 34px;
  line-height: 17px;
  color: #232d4c;
  margin-top:4px;
}

.Card_StudentsApplied__cqZf7 {
  /* position: absolute;
  height: 18px;
  left: 18px;
  top: 98px; */
  /* font-family: SF Pro Display; */
  /* font-style: normal; */
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #232d4c;
  margin-top:8px;
  transition: opacity 0.15s ease-in-out;
}

.Card_StudentsNumber__1BWvE {
  font-weight: bold;
  font-size: 44px;
  color: #4a90e2;
}

.Card_Loading__anvHa .Card_StudentsNumber__1BWvE{
  color: transparent;
}
.Card_BlueNextButton__1Mver {
  /* position: absolute;
  width: 9px;
  height: 14px;
  right: 20px;
  bottom: 33px; */
  float: right;
}



.Card_NewApplicants__3Muh6{
  position: absolute;
  padding: 10px;
  text-align: center;
  color: white;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 24px 24px 0px 0px;
  background: #FF5670;
  padding-bottom: 40px;
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  font-weight: 300;
}

.Card_NewApplicantsAppear__gBVgg{
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
}

.Card_NewApplicantsAppearActive__6Dimk{
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  transition: -webkit-transform 0.5s cubic-bezier(0.22, 0.61, 0.24, 1.58);
  transition: transform 0.5s cubic-bezier(0.22, 0.61, 0.24, 1.58);
  transition: transform 0.5s cubic-bezier(0.22, 0.61, 0.24, 1.58), -webkit-transform 0.5s cubic-bezier(0.22, 0.61, 0.24, 1.58);
}

.Card_Loading__anvHa{
  background: linear-gradient(90deg,#fff0, #ffffff52, #fff0);
  background-color: #ffffff42;
  content-visibility: hidden;
  height: 30px;
  border-radius: 5px;
  color:transparent;
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: -40px 0%;
  -webkit-animation: Card_loading__2HoVk 1s ease infinite;
          animation: Card_loading__2HoVk 1s ease infinite;
  width: 50%;
}

@-webkit-keyframes Card_loading__2HoVk{
  to{
    background-position: right -40px top 0%;
  }
}

@keyframes Card_loading__2HoVk{
  to{
    background-position: right -40px top 0%;
  }
}

.Card_Container__cJ1TF img,
.Card_Container__cJ1TF span,
.Card_StudentsApplied__cqZf7 {
  transition: opacity 0.15s ease-in-out;
}

.Card_Disabled__3yZTG.Card_Container__cJ1TF{
  cursor: default;
}

.Card_Disabled__3yZTG img{
  display: none;
}

.Card_CardContentContainer__1pNbe.Card_Disabled__3yZTG .Card_StudentsApplied__cqZf7{
  opacity: 0.5;
}

.Card_Container__cJ1TF.Card_Disabled__3yZTG span{
  opacity: 0.5;
}


.Loader_lds-ring__3Z6Bm {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    margin:auto;
  }
  .Loader_lds-ring__3Z6Bm div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    -webkit-animation: Loader_lds-ring__3Z6Bm 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: Loader_lds-ring__3Z6Bm 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #232d4c transparent transparent transparent;
  }
  .Loader_lds-ring__3Z6Bm div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .Loader_lds-ring__3Z6Bm div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .Loader_lds-ring__3Z6Bm div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes Loader_lds-ring__3Z6Bm {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes Loader_lds-ring__3Z6Bm {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.deletionmodal_title__7-Bw3{
    font-size: 18px;
    /* line-height: 33px; */
    text-align: center;
    color: #232D4C;
    padding:5px;
    font-weight: 500;
    margin-bottom: 10px;
}

.deletionmodal_container__ajvMu{
    text-align: center;
    padding: 0 20px;
    padding-top: 28px;
}

.deletionmodal_buttonsdiv__2IcNF{
    height:52px;
    display:flex;
    width: 100%;
}

.deletionmodal_cancelButton__1DK2P{
    background: #FFFFFF;
    border-radius: 0px 0px 0 14px;
    display:inline-block;
    width:100%;
    vertical-align: center;
    border: 1px solid #F4F3F3;
    vertical-align: center;
    display:grid;
    place-items: center;
    text-align: center;
}

.deletionmodal_deleteButton__Ns4Kx{
    background: #FFFFFF;
    border-radius: 0px 0px 14px 0px;
    display:inline-block;
    width:100%;
    vertical-align: center;
    border: 1px solid #F4F3F3;
    vertical-align: center;
    display:grid;
    place-items: center;
    text-align: center;
}

.deletionmodal_padder__mXSQd{
    font-size: 13px;
    font-weight: 200;
    width:100%;
    padding: 19px 0 19px 0;
    cursor: pointer;
}

.deletionmodal_padder__mXSQd:hover{
    background-color: #eee;
}


.profile_container__1XLBu{
    min-height:100vh;
    width:100vw;
    display:grid;
    grid-template-columns: 366px 1fr;
}

.profile_leftcontainer__tNeHL{
    width:366px;
    height: 100vh;
    overflow-y: auto;
    display:inline-block;
    vertical-align: top;
    overflow:hidden;
}

.profile_logo__1o8um {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile_socialContainer__26-OB{
    display:grid;
    /* grid-template-columns: 1fr 20px; */
}

.profile_rightcontainer__2ypr6{
    /* width: 72vw; */
    display:flex;
    flex-direction: column;
    vertical-align: top;
    height: 100vh;
    overflow-y: auto;
    /* padding-bottom: 100px; */
    /* padding-right: 44px; */
    background: radial-gradient(166.67% 269.75% at 85.98% 167.57%, #DCE8F6 0%, #FFFFFF 100%);
}
.profile_rightcontainer__2ypr6:after {
    content: "";
    display: block;
    height: 100px;
    flex-shrink: 0;
    width: 100%;
  }

.profile_title__1zXL4{
    display:inline-block;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: #9196A5;
    /* padding-bottom: 30px; */
    padding-top: 44px;
    padding-left:45px;
}

.profile_gridBox__3AP5C{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px 24px;
    gap: 0px 24px;
    padding:44px;
    padding-top:0px;
}

.profile_logout__LwEnp{
    padding-top: 80px;
}

.profile_leftForm__3bgV0{
    /* width: 49%; */
    display:inline-block;
    padding-top:30px;
}

.profile_rightForm__3vout{
    float:right;
    padding-top:30px;
    /* width:50%; */
    vertical-align: top;
}

.profile_logocontainer__YHYE8{
    position:relative;
    left: 55px;
    top: -101px;
    width: 129px;
    height: 125.35px;
    background: #FFFFFF;
    box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.22);
    border-radius: 12px;
    place-items: center;
    display:grid;
    overflow: auto;
    border: 2px solid white;
}

.profile_companyDetails__2Sbm8{
    margin-top: -80px;
    padding-left: 53px;
    padding-right: 15px;
}

.profile_companyName__1qf1-{
    height: 29px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #232D4C;
}

.profile_Industry__1V0XR{
    padding-top:3px;
    height: 17px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #232D4C;
}

.profile_otherDetails__3vul7{
    padding: 48px 0 0 0;
}

.profile_website__1HUap{
    height: 17px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #4A90E2;
    display:inline-block;
    vertical-align: top;
    padding-left: 4px;
}

.profile_link__-_SUL{
    text-decoration: none;
}

.profile_address__WvEc4{
    padding-top: 10px;
}

.profile_location__1wvGr{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #9196A5;
    width:260px;
    display:inline-block;
    vertical-align: top;
    padding-top: 2px;
}

.profile_nooutline__3Gk3P:focus{
    outline:none;
}

.profile_addmore__1xXGb{
    height: 17px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4A90E2;
    cursor:pointer;
}

.StepOne_leftcontainer__27fWk{
    display:inline-block;
    height:100vh;
    vertical-align: top;
    width: 380px;
}

.StepOne_leftimage__cMxDY{
    align-self: bottom;
}

.StepOne_container__1qorg{
    width:100vw;
    height:100vh;
    display: grid;
    grid-template-columns: 380px 1fr;
}

.StepOne_rightcontainer__1XxqO{
    display:flex;
    /* width: 75vw; */
    min-height: 100vh;
    padding: 38px 69px 57px 69px;
    background: #F1F2F3;
    flex-direction: column;
    vertical-align: top;
    height: 100vh;
    overflow-y: auto;
}

.StepOne_formcontainer__29ItZ{
    padding-right:100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px 50px;
    gap: 0px 50px;
}

@media (max-width:1000px) {
    .StepOne_formcontainer__29ItZ{
        grid-template-columns: 1fr;
    }
    .StepOne_rightForm__1emOT{
        margin-top:20px;
    }
}

.StepOne_uppercontainer__pGa2o{
    padding: 54px;
    display:block;
}

.StepOne_tellus__3Hp25{
    height: 36px;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #9196A5;
}

.StepOne_companylogodiv__3-Yel{
    padding: 27px 0 24px 0;
}

.StepOne_label__3ZAwb{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #232D4C;
}

.StepOne_inputbox__1fqVV{
    width: 776px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D3D5DB;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top:8px;
    padding: 12px;
    font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif ;
}

.StepOne_inputcontainer__-o0lu{
    padding-bottom: 24px;
    display:inline-block;
    /* margin-right: 21px; */
}

.StepOne_submitButtoninactive__3CvpR{
    width: 150px;
    height: 50px;
    background: #D3D5DB;
    border-radius: 10px;
    color:white;
    display:block;
}

.StepOne_submitButton__2CG5D{
    width: 150px;
    height: 50px;
    background: #4A90E2;
    border-radius: 10px;
    color:white;
    display:block;
}

.StepOne_leftForm__1yqAb{
    display:inline-block;
    /* width: 355px; */
    vertical-align: top;
}
.StepOne_rightForm__1emOT{
    /* width: 355px; */
    vertical-align: top;
    float: right;
}

.StepOne_addmore__1PKgv{
    height: 17px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4A90E2;
    cursor:pointer;
}

.StepOne_createButton__oASbP{
    width: 373px;
    height: 51px;
    background: #4A90E2;
    border-radius: 10px;
    color:white;
    margin-top: 44px;
}

.StepOne_createButtonInactive__1bK6F{
    width: 373px;
    height: 51px;
    background: #D3D5DB;
    border-radius: 10px;
    color:white;
    margin-top: 44px; 
}

.StepOne_hide__ORoET{
    display:none;
}

.StepOne_nooutline__1cuD5:focus{
    outline:none;
}

.StepOne_logoName__3YqKG{
    /* width: 165px;
    height: 115px; */
    width: 120px;
    height: 120px;
    background: #4A90E2;
    border: 1px dashed #4A90E2;
    box-sizing: border-box;
    border-radius: 12px;
    /* text-align: center; */
    /* vertical-align: center; */
    color: #F1F2F3;
    place-items: center;
    /* padding-top: 31px; */
    display: grid
}

.StepOne_CreateAccount__2NddF{
    padding-top: 26px;
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
    color: #0D0C21;
}

.StepOne_stepsContainer__Z6A9Y{
    padding-top: 32px;
    padding-left: 17px;
    vertical-align: top;
}

.StepOne_currentstep__2nn83{
    display:inline-block;
    padding-left: 12px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #4A90E2;
    vertical-align: top;
}

.StepOne_incompletestep__bYYXF{
    padding-top:16px;
    padding-left: 12px;
    display:inline-block;
    font-size: 20px;
    line-height: 24px;
    color: #9196A5;
    vertical-align: top;
}

.StepOne_bottomImage__3j0Eo{
    vertical-align: bottom;
    position:absolute;
    bottom:0
}

.StepOne_completedstep__NRWy-{
    display:inline-block;
    padding-left: 12px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #44D7B6;
    vertical-align: top;
}

.StepOne_filledLogo__2zzH8{
    text-align: center;
    word-break: break-word;
}

.StepOne_logopreviewimg__3GGNU{
    /* width: 120px;
    height: 120px; */
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0px 0px 12px 0px #ddbbbb;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.StepOne_logoinputdiv__2UuyE{
    height: 120px;
    width: 120px;
    border: 1px dashed #4A90E2;
    background-color: #FFFFFF;
    cursor:pointer;  
    place-items: center;
    display: grid;
    border-radius: 12px;
}

.StepOne_editPencil__3_6Gk{
    position: absolute;
    top: 11px;
    left: 100%;
    width: 43px;
    border-radius: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    cursor: pointer;
    padding: 5px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-shadow: -3px 8px 20px -11px #bdbdbd;
}

.StepOne_socialContainer__2y_ur{
    display:grid;
    /* grid-template-columns: 1fr 20px; */
}

.StepOne_logopreviewdiv__1DtHf{
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    display:inline-block;
    position:relative;
    border-radius:'12px';
    object-fit: cover;
}

.SuccessModal_title__3HydW{
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    color: #232D4C;
    padding:5px;
}

.SuccessModal_container__wBOh-{
    text-align: center;
    margin-top:-20px;
}

.SuccessModal_subtitle__3SLPI{
    height: 19px;
    font-size: 14px;
    line-height: 135.2%;
    text-align: center;
    color: #000000;
    /* padding-top: 14px; */
    padding: 14px 20px 14px 20px;
}

.SuccessModal_button__3Q3TA{
    width: 281px;
    height: 64px;
    background: #4A90E2;
    border-radius: 10px;
    color:white;
    margin-top:38px;
}
.ErrorBox_ErrorBox__1ul3n{ 
    
    
    background: #ff000017;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    color: #f04949;
    margin-top: -16px;
    margin-bottom: 16px;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
    position: relative;
}


.ErrorBox_ErrorBox__1ul3n p {
    margin: 0;
    padding: 10px;
    font-size: 15px;
    font-weight: 300;
    position: absolute;
    opacity: 0;

}

.ErrorBox_ErrorBox__1ul3n.ErrorBox_Active__361iP
{
    opacity: 1;
}

.ErrorBox_ErrorBox__1ul3n.ErrorBox_Active__361iP p
{
    -webkit-animation: ErrorBox_fadein__3Ny0s 0.2s forwards ease-in-out 0.15s;
            animation: ErrorBox_fadein__3Ny0s 0.2s forwards ease-in-out 0.15s;
}

@-webkit-keyframes ErrorBox_fadein__3Ny0s {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes ErrorBox_fadein__3Ny0s {
    from {opacity: 0;}
    to {opacity: 1;}
}
.Login_LoginGrid__2QLR1
{
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: minmax(540px, 1fr) 1.5fr;
    height: 100vh;
}

.Login_LoginSide__29woM 
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left:80px;
    padding-right:120px
}

.Login_Logo__fgq_i
{
    width: 120px;
}

.Login_IllustrationSide__Bs_PB
{
    background: #F4F4F6;
    overflow: hidden;
    position: relative;
}

.Login_LoginShapes__2k4Qk{
    padding-left:10%;
    width: 100%;
    height: 100%;
    object-position: 0% 100%;
    object-fit: cover;
}

.Login_H1__1RWHR 
{
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 32px;
    margin-top:23px;
    color: #0D0C21;
}

.Login_linksContainer__2DTuH{
    padding-top:14px;
}

.Login_linkText__eRgHq{
    font-size: 16px;
    line-height: 19px;
    color: #4A90E2;
}


.Partners_PartnersModal__2HAmX{
    position: absolute;
    width: 75%;
    max-width: 600px;
    height: 80vh;
    top: 50%;
    left: 50%;
    background-color: white;
    background: #FFFFFF;
    box-shadow: 24px 24px 33px -10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    -webkit-transform: translate(-40%, -50%);
            transform: translate(-40%, -50%);
    overflow: hidden;
    padding-bottom: 20px;
}

.Partners_TopBar__2BEgi{
    background-color: #232D4C;
    padding : 16px;
    color:white;
    display: flex;
    grid-gap:20px;
    gap:20px;
    align-items: baseline;
}

.Partners_DotContainer__oeYf1{
    display: flex;
    grid-gap:6px;
    gap:6px;
}

.Partners_DotContainer__oeYf1 span{
    height: 8px;
    width: 8px;
    border-radius: 5px;
    background: white;
}

.Partners_DotContainer__oeYf1 :nth-child(2){
    opacity: 0.66;
}

.Partners_DotContainer__oeYf1 :nth-child(3){
    opacity: 0.33;
}

.Partners_PartnersContent__N3CLs{
    height: calc((100% - 56px)/2);
    padding: 20px 40px;
    padding-top: 1px;
}


.Partners_PartnersGrid__1ofp6{
    max-height: calc(100% - 64px);
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-gap:20px;
    gap:20px;
    align-items: center;
    justify-content: center;
}

.Partners_PartnerTitle__3XCBW{
    padding: 20px 0px;
}

.Partners_PartnerTile__krnw4{
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
}

.Partners_PartnerTile__krnw4 span{
    font-size: 14px;
    line-height: 1.1em;
    font-weight: 300;
    margin-top: 10px;
    display: block;
    color: #5c5c5c;
    height: 20px;
}

.Partners_Logo__3GK6A{
    max-height:90px;
    display: block;
    margin: auto;
    object-fit: contain;
}
.StudentsHeader_StudentsHeader__2S3ok {
    background: #232D4C;
    border-radius: 0px 0px 40px 40px;
    color: white;
    padding: 40px 52px;
    text-align: center;
}

.StudentsHeader_Title__3GKEX {
    font-size: 36px;
    font-weight: 500;
    margin-top:30px;
    margin-bottom: 5px;
}

.StudentsHeader_Loading__1xhjC .StudentsHeader_Title__3GKEX{
    background-color: #e7e7e7;
    opacity: 0.2;
    color:transparent;
    /* height: 41.6px; */
    width: 80%;
    border-radius: 5px;
    max-width: 500px;
    margin-left:auto;
    margin-right: auto;
}

.StudentsHeader_SubTitle__1JHN- 
{
    font-weight: 500;
    font-size: 26px;
    opacity: 0.5;
}

.StudentsHeader_Loading__1xhjC .StudentsHeader_SubTitle__1JHN-{
    background-color: #e7e7e7;
    opacity: 0.2;
    content-visibility: hidden;
    height: 29.6px;
    width: 60%;
    border-radius: 5px;
    max-width: 350px;
    margin-left:auto;
    margin-right: auto;
}


.StudentsHeader_BackButton__h0b7Q {
    position: absolute;
    left: 50px;
}

.StudentsHeader_RightButtonTray__2PTDT{
    position: absolute;
    right: 50px;
    display: flex;
    grid-gap:8px;
    gap:8px;
}

.StudentsHeader_TopRightButton__2f76m{
    border:1px solid white;
    background: transparent;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    white-space: nowrap;
}
.SearchBar_SearchRow__1M9KY{
    display: flex;
    max-width: 1100px;
    margin:auto;
    margin-top: 22px;
    margin-bottom: -65px;
}

.SearchBar_SearchInput__vgxe4{
    font-size: 16px;
    font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif ;
    padding:20px 27px;
    border-radius: 10px;
    border: 1px solid #D3D5DB;
    box-shadow: 0px 22px 24px -20px rgba(0, 0, 0, 0.25);
    width: 100%;
}

.SearchBar_SearchInput__vgxe4:focus{
    outline: none;
    border-color: #4A90E2;
}

.SearchBar_Icon__2E4w4{
    display: block;
}
.tagsearch_BottomNav__1bJDJ {
    margin-left: 20px;
    background-color: #fff;
    border-radius: 10px;;
    position: relative;
    box-shadow: 0px 29px 34px -20px rgba(0, 0, 0, 0.25);
}

.tagsearch_NavLinks__2pFWm{
    display: flex;
    position: relative;
    z-index: 1;
    height: 100%;
}


.tagsearch_ActiveMarker__XBgAM{
    width: 100px;
    transition: -webkit-transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9);
    transition: transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9);
    transition: transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9), -webkit-transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9);
    position:absolute;
    left: 0;
    top: 0;
    height: 100%;
}

.tagsearch_ActiveMarker__XBgAM div{
    border-radius: 10px 10px 10px 10px;
    background: #4A90E2;
    height: 100%;
    width: 100px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
    /* border-radius: 14px; */
    /* transition: transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9); */
}

/* .ActiveMarker div.Moving{
    animation: skew 0.3s cubic-bezier(0.64, 0, 0.1, 0.9);
} */

.tagsearch_ActiveMarker__XBgAM div.tagsearch_MovingLeft__3Y-M9 {
    -webkit-transform-origin: right;
            transform-origin: right;
    -webkit-animation: tagsearch_skew__3JfWl 0.4s ease-in-out;
            animation: tagsearch_skew__3JfWl 0.4s ease-in-out;
}

.tagsearch_ActiveMarker__XBgAM div.tagsearch_MovingRight__C9dA9{
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-animation: tagsearch_skew__3JfWl 0.4s ease-in-out;
            animation: tagsearch_skew__3JfWl 0.4s ease-in-out;
}


@-webkit-keyframes tagsearch_skew__3JfWl {
    0%{-webkit-transform: scaleX(1);transform: scaleX(1)}
    50%{-webkit-transform: scaleX(0.5);transform: scaleX(0.5)}
    100%{-webkit-transform: scaleX(1);transform: scaleX(1)}
}


@keyframes tagsearch_skew__3JfWl {
    0%{-webkit-transform: scaleX(1);transform: scaleX(1)}
    50%{-webkit-transform: scaleX(0.5);transform: scaleX(0.5)}
    100%{-webkit-transform: scaleX(1);transform: scaleX(1)}
}

.tagsearch_NavLink__2BTkl{
    width: 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding:18px;
    transition: background-color 0.15s ease-in-out;
    cursor: pointer;
    height: 100%;
}

.tagsearch_NavLink__2BTkl .tagsearch_Icon__iO7oN{
    height: 23px;
    -webkit-transform-origin: 50% 120%;
            transform-origin: 50% 120%;
    transition: -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

.tagsearch_NavLink__2BTkl span {
    margin-top:5px;
    font-size:14px;
    color:#9196A5;
}

/* .NavLink:hover{
    background-color: #f3f3f3;
} */

.tagsearch_NavLink__2BTkl:hover .tagsearch_Icon__iO7oN{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.tagsearch_NavLink__2BTkl.tagsearch_Active__3IbZJ span{
    color:#4A90E2;
}
.KebabMenu_Kebab__3HJKV{
    display: flex;
    grid-gap: 0.3em;
    gap: 0.3em;
    flex-direction: column;
    padding: 6px 15px;
    cursor: pointer;
}

.KebabMenu_Kebab__3HJKV > div{
    width: 0.3em;
    height: 0.3em;
    background-color: white;
    border-radius: 50%;
}


.KebabMenu_KebabContainer__3GCnP{
    position: relative;
}


.KebabMenu_DropdownMenu__36OaU{
    position: absolute;
    right: 0;
    top: 100%;
    /* width: 100%; */
    width: 200px;
    /* font-size: 0.9em; */
    z-index: 20;
}
.KebabMenu_DropdownMenu__36OaU::before{
    box-shadow: 0px 4px 15px 0px #0000002b;
    content: "";
    top:0;
    left:0;
    background: white;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #0000001f;
    border-radius: 10px;
}

.KebabMenu_DropdownMenuContent__QXjtS{
    position: relative;
    width: 100%;
    height: 100%;
    left:1px;
    top:1px;
    border-radius: 10px;
    overflow: hidden;
}

.KebabMenu_DropdownMenuEnter__3WFka::before{
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform:scaleY(0.5);
            transform:scaleY(0.5);
    opacity: 0;
}
.KebabMenu_DropdownMenuEnterActive__1gcMf::before{
    -webkit-transform:scaleY(1);
            transform:scaleY(1);
    opacity: 1;
    transition: opacity 0.1s ease-in-out, -webkit-transform .1s ease-out;
    transition: transform .1s ease-out, opacity 0.1s ease-in-out ;
    transition: transform .1s ease-out, opacity 0.1s ease-in-out, -webkit-transform .1s ease-out;
}

.KebabMenu_DropdownMenuEnter__3WFka .KebabMenu_DropdownMenuContent__QXjtS{
    opacity: 0;
}
.KebabMenu_DropdownMenuEnterActive__1gcMf .KebabMenu_DropdownMenuContent__QXjtS{
    opacity: 1;
    transition: opacity .05s ease-in-out;
}



.KebabMenu_DropdownMenuExit__1seqV::before{
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform:scaleY(1);
            transform:scaleY(1);
    opacity: 1;
}
.KebabMenu_DropdownMenuExitActive__3-zX5::before{
    -webkit-transform:scaleY(0.5);
            transform:scaleY(0.5);
    opacity: 0;
    transition: opacity 0.1s ease-in-out 0.05s, -webkit-transform .1s ease-out 0.05s;
    transition: transform .1s ease-out 0.05s, opacity 0.1s ease-in-out 0.05s ;
    transition: transform .1s ease-out 0.05s, opacity 0.1s ease-in-out 0.05s, -webkit-transform .1s ease-out 0.05s;
}

.KebabMenu_DropdownMenuExit__1seqV .KebabMenu_DropdownMenuContent__QXjtS{
    opacity: 1;
}
.KebabMenu_DropdownMenuExitActive__3-zX5 .KebabMenu_DropdownMenuContent__QXjtS{
    opacity: 0;
    transition: opacity .1s ease-in-out;
}


.KebabMenu_Option__3jR81{
    font-family: 'Poppins';
    color: grey;
    padding:12px 15px;
    cursor: pointer;
    display: flex;
    grid-gap:10px;
    gap:10px;
    width: 100%;
    align-items: center;
    transition: background-color 0.05s ease-in-out;
}

.KebabMenu_Option__3jR81:hover{
    background: #eee;
}

.KebabMenu_Option__3jR81:active{
    background: #ddd;
}
.StudentCard_StudentCard__2RaUA {
    border: 1px solid #D3D5DB;
    box-sizing: border-box;
    border-radius: 14px;    
    background: white;
    padding: 22px 18px;
    padding-right: 0;
}

.StudentCard_StudentImage__3B4KG{
    border-radius: 12px;
    width: 106px;
    height: 103px;
    overflow: hidden;
    object-fit: cover;
}

.StudentCard_StudentInfo__3Xg1F{
    display: flex;
    margin-bottom: 16px;
    grid-gap: 16px;
}

.StudentCard_StudentName__10CrQ{
    font-size: 24px;
    color:#232D4C;
}
.StudentCard_StudentSubTitle__V7VRi{
    color:#9196A5;
    font-size: 12px;
    line-height: 14px;
    margin-top:2px
}

.StudentCard_RatingAndView__3YzSF 
{
    display: flex;
    grid-gap:20px;
    margin-top:12px;
}

.StudentCard_Rating__z7M5h{
    font-size: 20px;
    padding:  9px 27px 14px 19px;
    background: #F4F4F6;
    border-radius: 10px;
    flex-shrink: 0;
}
.StudentCard_Rating__z7M5h :first-child{
    font-weight: 500;
    color: gray;
}
.StudentCard_Rating__z7M5h :last-child{
    font-weight:700;
    color: #4A90E2;
}

.StudentCard_SkillsRow__6R-FQ h4 {
    font-size: 14px;
    color:#9196A5;
    font-weight: 400;

}
.StudentCard_SkillList__3f9pN{
    list-style: none;
    display: flex;
    margin-top: 8px;
}

.StudentCard_SkillList__3f9pN li 
{
    background: #FFD666;
    border-radius: 4px;
    padding: 3px 11px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #E09320;
    margin: 0px 5px;
}

.StudentCard_SkillList__3f9pN :first-child{
    margin-left: 0;
}

.StudentCard_SkillList__3f9pN :last-child{
    margin-right: 0;
}

.StudentCard_Loading__v2FGL .StudentCard_StudentImage__3B4KG{
    background-color: #e7e7e7;
    /* object-fit: none; */
}

.StudentCard_Loading__v2FGL .StudentCard_StudentName__10CrQ{
    background-color: #e7e7e7;
    color: transparent;
    /* width: 80%; */
    border-radius: 5px;
    height: 28px;
}

.StudentCard_Loading__v2FGL Button {
    display: none;
}

.StudentCard_Loading__v2FGL .StudentCard_StudentData__3Q1qN {
    min-width: 60%;
}

.StudentCard_Loading__v2FGL .StudentCard_StudentSubTitle__V7VRi{
    background-color: #e7e7e7;
    border-radius: 5px;
    margin-top:11px;
    width: 50%;
    height: 17px;
    content-visibility: hidden;
}

.StudentCard_excellent__b0TG6{
    float:right;
    margin-right: -3px;
}

/* .GOOD{
    height: 23px;
    background: #9196A5;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    float:right;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    text-align: center;
    vertical-align: center;
    padding: 7px 10px 0 10px;   
}

.AVERAGE{
    border: 1px solid #9196A5;
    height: 23px;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    float:right;
    letter-spacing: -0.01em;
    color: #9196A5;
    text-align: center;
    vertical-align: center;
    padding: 7px 10px 0 10px;   
}

.NEW{
    height: 23px;
    background: #FF5670;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    float:right;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    text-align: center;
    vertical-align: center;
    padding: 7px 10px 0 10px;
} */
.ProfilePicture_ProfilePictureContainer__1AW0e{
    width: 100%;
    height: 100%;
    position: relative;
}

.ProfilePicture_ProfilePictureContainer__1AW0e img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ProfilePicture_ProfilePictureContainer__1AW0e .ProfilePicture_ProfilePicture__3W3zI{
    position: absolute;
    top:0;
    left:0;
    transition: opacity 0.2s ease-in-out;
}
.StudentList_StudentListContainer__1MZpg
{
    background: #F4F4F6;
    padding: 64px;
    padding-top:84px;
}

.StudentList_StudentList__3z_Fa{
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}
.CandidateTagger_outercontainer__1pxkX{
    width:100%;
    padding: 20px 31px 0px 31px;
    display:grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
}

.CandidateTagger_innercontainer__35opq{
    background: #F4F4F6;
    padding-top:10px;
    padding-left: 13px; 
    padding-right: 13px;
    
    border-radius: 10px;
    vertical-align: top;
}

.CandidateTagger_contactContainer__XslCQ{
    vertical-align: top;
    /* height: 108px; */
    background: #FFFFFF;
    border: 1px solid #D3D5DB;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 16px 26px 22px 26px;
    /* display:inline-block; */
    /* margin-left: 30px; */
    /* width:376px; */
    /* width: 50%; */
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
}

.CandidateTagger_contactContainer__XslCQ.CandidateTagger_Loading__2uG1S{
    background: #f4f4f4;
    color:transparent;
    border:none;
}

.CandidateTagger_contactContainer__XslCQ.CandidateTagger_Loading__2uG1S img,
.CandidateTagger_contactContainer__XslCQ.CandidateTagger_Loading__2uG1S .CandidateTagger_number__L49rJ{
    opacity: 0;
}

.CandidateTagger_candidatetaggertext__1vYRI{
    height: 17px;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
}

.CandidateTagger_numberdiv__2vjbE{
    padding-top:8px;
    vertical-align: top;

}

.CandidateTagger_number__L49rJ{
    height: 19px;
    font-size: 16px;
    line-height: 19px;
    color: #9196A5;
    display:inline-block;
    vertical-align: top;
    padding-top:-4px;
    padding-left: 11px;
}




.CandidateTagger_spantext__2T2Jj{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4A90E2;
}

.CandidateTagger_buttoncontainer__3v0wl{
    padding:12px 0 22px 0; 
    display: flex;
    flex-direction: row;
    grid-gap:16px;
    gap:16px;
}

.CandidateTagger_printOnly__158in{
    display: none;
}




@media print {
    .CandidateTagger_innercontainer__35opq, .CandidateTagger_contactContainer__XslCQ{
      display:none;
    }
    .CandidateTagger_printOnly__158in{
        display: block;
        text-transform: lowercase;
    }
    .CandidateTagger_outercontainer__1pxkX{
        grid-template-columns: 1fr;
        text-align: right;
    }
    .CandidateTagger_numberDiv__3VVVL{
        padding-top:0;
    }
  }

.StandaloneResume_ResumeWrapper__3Dh8B {
    width: 80%;
    max-width: 1500px;
    margin: 0px auto;
    margin-bottom: 90px;
    border-radius: 15px;
    overflow: hidden;
    padding:40px 40px 0px 40px;
    background:white;
}

.StandaloneResume_Main__1WfMm {
    background: #f2f2f3;
    padding: 1px;
}

.StandaloneResume_Logo__WHRws {
    height: 25px;
    /* width: 200px; */
}

.StandaloneResume_Back__3gB1u{
    opacity: 0.5;
    float: left;
}

.StandaloneResume_Header__bUpcZ{
    text-align: center;
    padding:50px;
    margin-top: 10px;
}
.AppLoader_LoadingLogo__2MROi {
    line-height: 1;
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
  
    -webkit-transform: translate(-50%, -50%);
  
            transform: translate(-50%, -50%);
    animation: AppLoader_bubbling__2_lXm 1s ease-in-out alternate-reverse infinite;
  }
  
  .AppLoader_LoadingLogo__2MROi h1 {
    margin: 0;
    font-size: 92px;
    font-weight: 900;
  }
  
  @-webkit-keyframes AppLoader_bubbling__2_lXm {
    from {
      -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1);
    }
    to {
      -webkit-transform: translate(-50%, -50%) scale(1.1);
              transform: translate(-50%, -50%) scale(1.1);
    }
  }
  
  @keyframes AppLoader_bubbling__2_lXm {
    from {
      -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1);
    }
    to {
      -webkit-transform: translate(-50%, -50%) scale(1.1);
              transform: translate(-50%, -50%) scale(1.1);
    }
  }
  
.CreateAccount_container__2ugPy{
    margin:0;
    padding:0;
}
.forgot_LoginGrid__2Nmz_
{
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: minmax(540px, 1fr) 1.5fr;
    height: 100vh;
}


.forgot_LoginSide__2idPk 
{
    display:grid;
    /* display: flex; */
    /* flex-direction: column; */
    place-items: center;
    padding-left:80px;
    padding-right:120px;
    vertical-align: middle;
    height: 100vh;
    margin-left: 60px;
}

.forgot_H1__3UOqZ 
{
    font-weight: 600;
    font-size: 44px;
    line-height: 43px;
    margin-top:23px;
    color: #0D0C21;
}

.forgot_IllustrationSide__rLaK-
{
    display:grid;
    place-items: center;
    background: #ffffff;
    overflow: hidden;
}

.forgot_DescriptionText__38wax{
    /* width: 250px; */
    height: 38px;
    font-size: 14px;
    line-height: 135.2%;
    color: #000000;
    margin-top: 13px;
    margin-bottom: 36px;
}

.forgot_label__11kiL{
    font-weight: 400;
    font-size: 12px;
    line-height: 106.2%;
    color: #28292E;
    padding-bottom: 9px;
}
.SuccessModal2_CloseBtn__3kom5{
    width: 100%;
    padding:15px;
    cursor: pointer;
    border-top: 1px solid #e3e2e2;
    margin-top:30px;
    font-family: 'poppins';
    outline: none;
}

.SuccessModal2_CloseBtn__3kom5:active{
    background-color: #eee;   
}

.SuccessModal2_Container__pKgHT{
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.SuccessModal2_Tick__1qcSN{
    display: block;
    height: 90px;
    margin:auto;
    margin-top:45px;
}

.SuccessModal2_Title__2Kzf5{
    font-weight: bold;
    margin-top:11px; 
    margin-bottom: 4px;
    font-size: 20px;
    text-align: center;
    color:#232D4C;
}

.SuccessModal2_Subtitle__1gpfH{
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.191177px;
    color: #969BA9;
}
.Toasts_Toast__178Lg{
    /* position: fixed;
    right: 40px;
    bottom: 20px; */
    padding: 18px 40px;
    background-color: #44D5BA;
    color: #fff;
    border-radius: 10px;
    padding-right: 0px;
    transition: opacity 1s ease-in-out;
}

.Toasts_Cross__B7ZtZ{
    height: 10px;
}

.Toasts_CrossBtn__1qtD2{
    margin: -30px 0;
    border: none;
    display: inline-flex;
    padding: 20px 20px;
    margin-left: 3px;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    cursor: pointer;
    outline: none;
}

.Toasts_Toast__178Lg:hover .Toasts_CrossBtn__1qtD2{
    opacity: 1;
}


.Toasts_ToastContainer__3_LQF {
    position: fixed;
    bottom: 40px;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-gap: 12px;
    gap: 12px;
    z-index: 200;
}

.Toasts_ToastEnter__1U6Xw{
    opacity: 0;
    -webkit-transform:translateX(0%) scale(0.7);
            transform:translateX(0%) scale(0.7);
}

.Toasts_ToastEnterActive__3irla{
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, opacity 0.3s ease-in-out;
    transition: transform 0.3s ease-out, opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-out;
}

.Toasts_ToastExit__1i71C {
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
.Toasts_ToastExitActive__3G0RT {
    opacity: 0;
    -webkit-transform: translateX(0) scale(0.7);
            transform: translateX(0) scale(0.7);
    transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-out;
  }
.SideBar_SideBar__2kqt1{
    background: #2D3038;
    box-shadow: 3px -1px 49px rgb(0 0 0 / 49%);
    
}

.SideBar_Logo__2hs4I{
    padding: 15px 30px;
    padding-top: 0px;
    /* width: 130px; */
    max-width: 190px;
}

.SideBar_SideBarContent__3wgxW{
    position: sticky;
    top: 0px;
    padding: 60px 20px ;
    min-height: 100vh;
}

.SideBar_NavList__2pIbp{ 
    margin-top: 30px;
    display: flex;
    flex-direction: column; 
    grid-gap: 10px; 
    gap: 10px;
}

.SideBar_NavItem__8jf5K{
    display: flex;
    color: #E0E0E0;
    border-radius: 38px;
    padding: 17px 30px;
    
}

.SideBar_NavItemActive__CdNxD{
    background: linear-gradient(
        153deg
        , #1E2024 0.97%, rgba(45, 48, 56, 0) 54.5%)
    
}

.SideBar_NavIcon__9yqTB{
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

.SideBar_UserDetailsContainer__2XG2- {
    display: flex;
    grid-gap:30px;
    gap:30px;
    padding: 20px;
    position: absolute;
    bottom: 40px;
}

.SideBar_UserImg__GSUbe{
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    border: none;
    position: relative;
}

.SideBar_UserImgContainer__2Utd5{
    position: relative;
}

.SideBar_UserImgContainer__2Utd5::before{
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    background: linear-gradient(180deg, #1E2024 17.64%, rgba(45, 48, 56, 0) 98.59%);
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.SideBar_UserDetails__2kH4Q span{
    display: block;
    line-height: 1.2em;
}


.SideBar_UserDetails__2kH4Q .SideBar_Title__2DazS{
    font-size: 14px;
    font-weight: 100;
}

.SideBar_UserDetails__2kH4Q .SideBar_Subtitle__2s8vw{
    font-size: 14px;
    font-weight: 400;
}
.Layout_MainContainer__1lPcB{
    background: #2D3038;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 300px 1fr;
    color: #E0E0E0;

}

.Layout_Main__18kKW {
    padding: 50px 80px;
}
.SearchPage_SearchSection__1Pu4V{
    padding: 40px 60px;
    background: #393F44;
    border-radius: 20px;
 
}

.SearchPage_IllusRow__3ZNmk {
    display: flex;
    align-items: center;
    grid-gap:10%;
    gap:10%;
}

.SearchPage_Illus__3w_c7 {
    height: 240px;
    flex-shrink: 0;
}

.SearchPage_Title__1YA8e{
    font-size: 25px;
    font-weight: 700;
}

p {
    color: #686B75;
}

.SearchPage_SearchRow__3fmPZ{
    margin-top: 40px;
}

.SearchPage_JobProfiles__3P1TV {
    margin-top: 50px;
}
.SearchInput_Input__3hk8q{
    background: transparent;
    border-color: transparent;
    padding: 12px 20px;
    color: #e4e4e4;
    line-height: 0.7;
    width: 100%;
    font-family: var(--font);
    /* padding-left: 48px; */
    height: 100%;
}


.SearchInput_Input__3hk8q:focus{
    outline: none;
}

.SearchInput_SearchContainer__PITBP{
    background: linear-gradient(145.75deg, #1E2024 13.71%, #393F44 65.34%);
    border-radius: 25px;
    /* border: 1px solid #51555B; */
    position: relative;
    /* flex-shrink: 0; */
    width: 100%;
    box-shadow: 0px 0px 0px 1px #51555B ;
    transition: 0.15s box-shadow ease-in-out;
}

.SearchInput_SearchContainer__PITBP:focus-within{
    box-shadow: 0px 0px 0px 2px #777b80 ;
}

.SearchInput_Icon__2tEvL { 
    height: 18px;
}

.SearchInput_SearchBtn__azkQo {
    position: absolute;
    right: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: linear-gradient(320.09deg, #1E2024 11.25%, #393F44 80.07%, #393F44 100% , #1E2024 300%  );
    border-radius: 50px;
    background-size: 150% 100%;
    height: calc(100% - 10px);
    padding: 0px 20px ;
    cursor: pointer;

    transition: 0.3s background-position ease-in-out;
}

.SearchInput_SearchBtn__azkQo:hover{
    background-position: 100% 0%;

}


.SearchInput_Suggestions__3x4P9 {
    position: absolute;
    top: 110%;
    width: 100%;
    left: 0;
    background:#2D3038;
    border-radius: 30px;
    padding: 5px 20px;
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    border: #666666 1px solid;

}

.SearchInput_Suggestions__3x4P9 span{
    padding: 15px 10px;
    display: block;
    border-bottom: solid 1px #B6B6B6 ;
}

.SearchInput_Suggestions__3x4P9 span:hover {
    
}

.SearchInput_Suggestions__3x4P9 span:last-of-type{
    border-bottom: none;
}  
.JobProfiles_Tag__1Cmfa {
    padding: 13px 25px;
    background: rgba(210, 28, 28, 0.2);
    border: 1px solid #FF6E6E;
    box-sizing: border-box;
    box-shadow: 0px 0px 44px rgba(255, 0, 0, 0.55), inset 0px 0px 44px rgba(255, 0, 0, 0.25);
    border-radius: 38px;
    display: inline-block;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    /* transition: transform 0.2s ease-in-out; */
    white-space: nowrap;
    transition: box-shadow 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out ;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.JobProfiles_Tag__1Cmfa:nth-of-type(2n){
    background: rgba(28, 153, 210, 0.2);
    border: 1px solid #6BD0FF;
    box-shadow: 0px 0px 44px rgba(28, 152, 210, 0.55), inset 0px 0px 44px rgba(28, 152, 210, 0.25);
}



.JobProfiles_Tag__1Cmfa:hover {
    -webkit-transform: scale(1.05) translateY(-3px);
            transform: scale(1.05) translateY(-3px);
    box-shadow: 0px 10px 44px -4px rgba(255, 0, 0, 0.50) , inset 0px 0px 33px rgba(255, 0, 0, 0.25);
}
.JobProfiles_Tag__1Cmfa:nth-of-type(2n):hover{
    box-shadow: 0px 10px 44px -4px rgba(28, 152, 210, 0.5), inset 0px 0px 33px rgba(28, 152, 210, 0.25);
}

.JobProfiles_Tag__1Cmfa:active{
    -webkit-transform: scale(1.02) translateY(-2px);
            transform: scale(1.02) translateY(-2px);
    box-shadow: 0px 0px 20px rgba(255, 0, 0, 0.60), inset 0px 0px 33px rgba(255, 0, 0, 0.25);
}

.JobProfiles_Tag__1Cmfa:nth-of-type(2n):active{
    box-shadow: 0px 0px 20px rgba(28, 152, 210, 0.60), inset 0px 0px 33px rgba(28, 152, 210, 0.25);
}



.JobProfiles_TagContent__1fHYi {
    display: inline-block;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    

}

.JobProfiles_Tag__1Cmfa:hover .JobProfiles_TagContent__1fHYi{
    /* transform: scale(0.97); */
}


h3 {
    /* font-weight: 800; */
    font-size: 20px;
}

.JobProfiles_JobProfilesList__3h59o {
    margin-top: 20px;
    display: flex;
    grid-gap: 15px 20px;
    gap: 15px 20px;
    flex-wrap: wrap;
}
.NoInvites_NoInvites__1l_pF{
    padding: 40px 60px;
    background: #393F44;
    border-radius: 20px;
    display: flex;
    align-items: center;
    grid-gap:10%;
    gap:10%;
}

.NoInvites_Illus__3aUcM {
    height: 240px;
    flex-shrink: 0;

}

.NoInvites_Content__umks7{

}

.NoInvites_Heading__3Hrbr{
    font-size: 24px;
    margin-bottom: 0;
    line-height: 1.3;
}

.NoInvites_SubText__32GDj{
    line-height: 1.3;
    margin-top:10px;
    color: #686B75;
}

.NoInvites_Button__3YXnG {
    background: linear-gradient(329.31deg, #236685 14.09%, #55B3DE 82.41%);
    border-radius: 25px;
    padding: 15px;
    width: 234px;
    color: #E0E0E0;
    font-size: 16px;
    margin-top: 20px;
    font-family: var(--font);
    cursor: pointer;
}
.InvitesSent_SearchRow__3YNuR{
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    margin-bottom: 20px;
}

.InvitesSent_Btn__DMADb{
    flex-shrink: 0;
}
.SearchBar_SearchBar__3woy7{
    background: transparent;
    border-color: transparent;
    padding: 12px 20px;
    color: white;
    line-height: 0.7;
    width: 100%;
    font-family: var(--font);
    padding-left: 48px;
    height: 100%;

}

.SearchBar_SearchBar__3woy7:focus{
    outline: none;
}

.SearchBar_SearchBarContainer__3Aq7x{
    background: linear-gradient(145.75deg, #1E2024 13.71%, #2D3038 65.34%);
    border-radius: 25px;
    border: 1px solid #51555B;
    position: relative;
    /* flex-shrink: 0; */
    width: 100%;
    transition: 0.15s border ease-in-out;
}

.SearchBar_SearchBarContainer__3Aq7x:focus-within{
    border: 2px solid #777b80;
}

.SearchBar_Icon__2F2Qg {
    
    position: absolute;
    left: 20px;
    top: 50%;
    height: 18px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    
}


.InviteeList_InviteeList__3b6RB {
    display: flex;
    flex-direction: column;
    grid-gap:20px;
    gap:20px

}
.Invitee_Invitee__3R3Pq{
    padding:30px;
    background: linear-gradient(145.75deg, #1E2024 13.71%, #2D3038 65.34%);
    border-radius: 20px;
    padding-bottom: 15px;
    min-height: 200px;
}

.Invitee_FirstRow__3udvT {
    display: flex;
    grid-gap:50px;
    gap:50px;
    justify-content: space-between;
}

.Invitee_ProfilePicture__3avIi{
    width: 80px;
    height: 80px;
}

.Invitee_Name__1gWm0 {
    font-size: 19px;
}

.Invitee_UserDetails__vGaSM {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.Invitee_Name__1gWm0 a:link,
.Invitee_Name__1gWm0 :visited{
    color: #3CB2E9;
    text-decoration: underline;
}
.Invitee_Arrow__2uVsP {
    width: 12px;
    margin-left: 5px;
    transition: -webkit-transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}
.Invitee_Name__1gWm0:hover .Invitee_Arrow__2uVsP{
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
}

.Invitee_Name__1gWm0:active .Invitee_Arrow__2uVsP{
    -webkit-transform: translate(-2px, 2px) scale(0.9);
            transform: translate(-2px, 2px) scale(0.9);
}

.Invitee_Desc__2ua9q {
    color: #686B75;
    font-size: 300;
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.2em;
}



.Invitee_PersonalDetails__Xqx1l {
    background: linear-gradient(145.75deg, #1E2024 13.71%, #2D3038 65.34%);
    background: linear-gradient(170deg, #1E2024 -24.29%, #2D3038 74.34%);
    border-radius: 13.6078px;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-self: flex-start;
    padding: 15px 20px;
}

.Invitee_Label__4N4wd{
    color: #686B75;
    margin-right: 5px;
}

.Invitee_Value__1gbib {
    font-weight: 700;
}

.Invitee_Awaiting__DS-xy {
    font-size: 17px;
    color: #44D7B7;
    background: linear-gradient(146.06deg, #1F2025 9.39%, #2E3038 82.08%);
    border-radius: 38px;
    padding: 15px 30px;
    align-self: center;
}
.SkillsSlider_Skill__bSWhT{
    padding: 8px 16px;
    border-radius: 30px;
    color:#E0E0E0;
    white-space: nowrap;
    background: linear-gradient(320.09deg, #1E2024 11.25%, #393F44 80.07%);
}

.SkillsSlider_SliderContent__BChiR {
    display: flex;
    grid-gap:15px;
    gap:15px;
    position: absolute;
    top: 0;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.SkillsSlider_ContentWraper__2uKCR{
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    padding-top: 40px;
}

.SkillsSlider_RightVisible__1NFfU .SkillsSlider_ContentWraper__2uKCR::after,
.SkillsSlider_LeftVisible__2Ulam .SkillsSlider_ContentWraper__2uKCR::before
{
    position: absolute;
    right: 0;
    top: 0;    
    content: "";
    width: 100px;
    height: 100%;
    background: linear-gradient(270.73deg, #2D3038 31.24%, rgba(118, 119, 121, 0) 100% );
    border-radius: 0px 20px 20px 0px;
}

.SkillsSlider_LeftVisible__2Ulam .SkillsSlider_ContentWraper__2uKCR::before{
    left: 0;
    z-index: 1;
    right: unset;
    background: linear-gradient(90deg, #202328 31.24%, rgb(0 63 190 / 0%) 100% );
    border-radius: 20px 0px 0px 20px;
}

.SkillsSlider_SkillsSlider__3HlNP {
    margin-top: 30px;
    /* padding-top: 40px; */
    position: relative;
}

.SkillsSlider_RightBtn__1Xb3S,
.SkillsSlider_LeftBtn__iDDTQ
{
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    background: linear-gradient(306.8deg, #1E2024 16.34%, #393F44 85.69%);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.45);
    width: 40px;
    color: white;
    cursor: pointer;
    border-radius: 50%;
    z-index: 2;
    transition: box-shadow 0.15s ease-in-out, -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.2s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.SkillsSlider_LeftBtn__iDDTQ {
    left: 0px;
    right: unset;
}

.SkillsSlider_RightBtn__1Xb3S:hover,
.SkillsSlider_LeftBtn__iDDTQ:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 5px 5px 35px 2px rgba(0, 0, 0, 0.45);
}

.SkillsSlider_RightBtn__1Xb3S:active,
.SkillsSlider_LeftBtn__iDDTQ:active{
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.45);
}
