.container{
    min-height:100vh;
    width:100vw;
    display:grid;
    grid-template-columns: 366px 1fr;
}

.leftcontainer{
    width:366px;
    height: 100vh;
    overflow-y: auto;
    display:inline-block;
    vertical-align: top;
    overflow:hidden;
}

.logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.socialContainer{
    display:grid;
    /* grid-template-columns: 1fr 20px; */
}

.rightcontainer{
    /* width: 72vw; */
    display:flex;
    flex-direction: column;
    vertical-align: top;
    height: 100vh;
    overflow-y: auto;
    /* padding-bottom: 100px; */
    /* padding-right: 44px; */
    background: radial-gradient(166.67% 269.75% at 85.98% 167.57%, #DCE8F6 0%, #FFFFFF 100%);
}
.rightcontainer:after {
    content: "";
    display: block;
    height: 100px;
    flex-shrink: 0;
    width: 100%;
  }

.title{
    display:inline-block;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: #9196A5;
    /* padding-bottom: 30px; */
    padding-top: 44px;
    padding-left:45px;
}

.gridBox{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 24px;
    padding:44px;
    padding-top:0px;
}

.logout{
    padding-top: 80px;
}

.leftForm{
    /* width: 49%; */
    display:inline-block;
    padding-top:30px;
}

.rightForm{
    float:right;
    padding-top:30px;
    /* width:50%; */
    vertical-align: top;
}

.logocontainer{
    position:relative;
    left: 55px;
    top: -101px;
    width: 129px;
    height: 125.35px;
    background: #FFFFFF;
    box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.22);
    border-radius: 12px;
    place-items: center;
    display:grid;
    overflow: auto;
    border: 2px solid white;
}

.companyDetails{
    margin-top: -80px;
    padding-left: 53px;
    padding-right: 15px;
}

.companyName{
    height: 29px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #232D4C;
}

.Industry{
    padding-top:3px;
    height: 17px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #232D4C;
}

.otherDetails{
    padding: 48px 0 0 0;
}

.website{
    height: 17px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #4A90E2;
    display:inline-block;
    vertical-align: top;
    padding-left: 4px;
}

.link{
    text-decoration: none;
}

.address{
    padding-top: 10px;
}

.location{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #9196A5;
    width:260px;
    display:inline-block;
    vertical-align: top;
    padding-top: 2px;
}

.nooutline:focus{
    outline:none;
}

.addmore{
    height: 17px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4A90E2;
    cursor:pointer;
}
