@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*{
  margin: 0;
  padding: 0;
}

img{
  overflow: hidden;
}

hr{
  height: 0px;
  border: none;
  border-bottom: 1px solid #D3D5DB;
}


.top-bar{

  height: 59px;
  background: #17203A;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
  border-radius: 14px 14px 0px 0px;
}

.cross-button{
  position: relative;
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 20px;
  /* left: 31px; */
  margin-top:20px;
  color: #232D4C;
  background-color: #FF5670;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
}

.x-button{
  margin-left: 0.8px;
  width: 8px;
}

.resume-container{
  /* width: 886px; */
  /* height: 400px; */
  word-break: break-word;
  padding-bottom: 1px;
  background-color: #FFFFFF;
  box-shadow: 0px 26px 24px -20px rgba(0, 0, 0, 0.25);
  border-radius:  0px 0px 14px 14px;
  text-transform: capitalize;
}

.profilepic{
  width: 160px;
  height: 154px;
  box-shadow: 0px 22px 20px -13px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 40px;
}

.profileimg{
  display: block;
  width: 160px;
  height: 154px;
  object-fit: cover;
  object-fit: center;
}

.applicant-info{
  width: 100%;
}

.profile-container{
  display: flex;
  margin: 0px 40px;
  gap: 30px;
  margin-top: 50px;
}

.applicant-name{

  font-weight: bold;
  font-size: 28px;
  color: #232D4C;
}

.applicant-details{
  margin-top: -4px;
  color: #232D4C;
}

.applicant-bio{
  margin-top: 10px;
  color: #9196A5;
  line-height: 1.3em;
  font-weight: 300;
  
}

.rating-box{
  width: 163px;
  height: 50px;
  background: #F4F4F6;
  border-radius: 10px;
  text-align: center;
  display:inline-block;

}

.rating-text{
  /* font-family: SF Pro Display; */
  position: relative;
  top:10px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #4A90E2;
  /* margin-top: 13.5px; */
}


.next-button-container{
  position: absolute;
    top: 0;
    right: -27px;
    height: 100%;
    min-width: 10px;
}

.download-button-container{
  position: absolute;
  /* top: 0; */
  height: 100%;
  /* cursor:pointer; */
  right: -40px;
  min-width: 10px;
}

.prev-button-container{
  position: absolute;
    top: 0;
    left: -27px;
    height: 100%;
    min-width: 10px;
}

.next-button{
  position: sticky;
  width: 54px;
  height: 54px;
  top: 40%;
  background: #27272D;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 7px 23px rgb(0 0 0 / 25%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-button{
  position: sticky;
  width: 54px;
  height: 54px;
  top: 40%;
  background: #27272D;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 7px 23px rgb(0 0 0 / 25%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadButton{
  position: sticky;
  width: 80px;
  height: 80px;
  top: 80%;
  /* background: #27272D; */
  /* border: 1px solid #FFFFFF; */
  /* box-shadow: 0px 7px 23px rgb(0 0 0 / 25%); */
  border-radius: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  cursor:pointer;
  z-index: 2;
}

.prev-button img {
  transform: rotate(180deg);
  flex-grow: 0;
}

.skills-heading{
  /* font-family: SF Pro Display; */
  font-size: 16px;
  color: #9196A5;
  margin-bottom: 5px;
}

.skills-section{
  margin:50px 40px;
  margin-top: 0px;
}


.skillBox{
  border-radius: 5px;
  display: inline-block;
  padding: 7px 18px;
  font-weight: 300;
  flex-shrink: 0;
}

.skillText{
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  word-break: break-word;
}

.skills-container{
  display: flex;
  flex-wrap: wrap;
  gap: 10px 5px;
}
.compact-skills{
  height: 34px;
  display: flex;
  gap:5px;
  flex-wrap: wrap;
  overflow: hidden;
}

.section-section{
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

.section-container{
  padding: 50px 60px 60px;
}

.section-title{
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18.5px;
  letter-spacing: -0.01em;
  color: #9196A5;
  padding-bottom: 5px;
}

.project{
  display: inline-block;
  vertical-align: top;
  /* padding: 5px 15px 15px 0; */
}

.experience-pic {
  /* width: 35px;
  height: 35px; */  /* opacity: 0.2; */
  border-radius: 4px;
  display: inline-block;
  align-items: flex-start;
  padding: 8px;
}

.project-name-flexbox{
  display:flex;
  align-items: flex-start;
  gap: 10px;
}

.project-name{
  position: relative;
  top: -2px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  /* word-break: break-word; */
  /* word-break: break-all; */
}

.project-title{
/* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18.5px;
  color: #000000;
  /* max-width: 230px; */
  overflow-wrap: break-word;
  /* word-break: break-all; */
}

.project-date{

  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #969BA9;
  text-transform: capitalize;
  margin-top: 3px;
  /* overflow-wrap: break-word; */
  /* word-break: break-all; */
}

.project-description{
  font-size: 15px;
  line-height: 20px;
  margin-top: 10px;
  color: #9196A5;
  font-weight: 300;
  /* overflow-wrap: break-word; */
  /* word-break: break-all; */

}

.visit-link-button{
  background: #FFFFFF;
  border: 1px solid #4A90E2;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  margin-left: 14px;
  padding: 6px 10px;
  flex-shrink: 0;
}

.title-date-div{
  display: inline-block;
  vertical-align: top;
  padding-top:2px;
}

.visit-text{

display: block;
font-size: 14px;
letter-spacing: -0.01em;
color: #4A90E2;
line-height: 1em;
}

.gold{
  background-color: #ffef99;
  color: #E09320;
}

.silver{
  background-color: #ececed;
  color: #898989;
}

.bronze{
  background-color: #e7d2c1;
  color: #AF6E38;
}

.no-level{
  border: 1px solid #D4D5DA;
  color: #000;
}

.loading{

  height: 27px;
  width: 83px;
  background-color: #e7e7e7;
}

.tagRemoverButton{
  margin-left: 13px;
}

.project-pic{
  width:25px;
}

/* Print Stuff */

.contact-container{
  padding-top:8px;
  display:none;
}

.nobreakOnPrint{
  page-break-inside: avoid;
  break-inside: avoid;
}

.dontBreakAfter{
  page-break-after: avoid;
  break-after: avoid;
}

.dontBreakBefore{
  page-break-before: avoid;
  break-before: avoid;
}

.status{
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  min-width: 200px;
}
.status.Hired,
.status-message.Hired{
 color:#57a3c8;
 background-color:#e6f3fb;
}

.status.Rejected,
.status-message.Rejected
{
  color:#ff4a4a;
  background-color:#ffeeef;
 }

 .status-message{
   text-transform: none;
   align-items: center;
   font-size: 16px;
   color:#444;
   background: #f5f5f5;
   padding: 20px 30px;
   text-align: center;
   border-radius: 5px;
 }

.side-button-enter .button{
  opacity: 0;
  transform: scale(0.7);
}
.side-button-enter-active .button{
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.1s ease-in-out, trasform 0.1s ease-in-out;
}

.side-button-exit .button,
.side-button-exit:hover .button{
  opacity: 1;
  transform: scale(1);
}
.side-button-exit-active .button,
.side-button-exit-active:hover .button{
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 0.1s ease-in-out , transform 0.1s ease-in-out;
}

/* Loading Styles */

.profile-container.Loading .applicant-name::after,
.profile-container.Loading .applicant-details::after,
.profile-container.Loading .applicant-bio::after,
.project.Loading .experience-pic::after,
.project.Loading .project-title::after,
.project.Loading .project-date:after,
.project.Loading .project-description:after
{
  position: absolute;
  top:0;
  left:0;
  content: "";
  border-radius: 5px;
  display: block;
  height: 80%;
  width: 50%;
  background: #e5e5e5;
}
.profile-container.Loading .applicant-details::after,
.project.Loading .project-date:after{
  width: 30%;
  height: 60%;
}
.profile-container.Loading .applicant-bio::after{
  width: 100%;
  height: 80%;
}

.project.Loading .experience-pic:after,
.project.Loading .project-description::after,
.project.Loading .project-title::after
{
  width: 100%;
  height: 100%; 
}

.profile-container.Loading .applicant-name,
.profile-container.Loading .applicant-details,
.profile-container.Loading .applicant-bio,
.project.Loading .experience-pic,
.project.Loading .project-title,
.project.Loading .project-date,
.project.Loading .project-description
{
  position: relative;
  color:transparent;
}

/* .project.Loading .experience-pic{


} */

@media print {
  .contact-container{
    display: block;
  }

  .hideOnPrint, .top-bar, .candididateTaggerDiv {
    display:none;
  }

  .resume-container{
    box-shadow: none
  }

  .section-title{
    font-size: 18px;
    color:#4A90E2;
    /* font-weight: 600; */
  }

  .project-pic{
    width: 15px;
  }

  .project-title, .project-date, .skillText{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000
  }

  .project-title{
    font-weight: 500;
    font-size: 14px;
  }

  .project-description{
    font-size: 10px;
    line-height: 11px;
    color: #000;
  }

  /* .skillText{
    font-size: 12px;
  } */

  .section-container{
    padding-top: 0;
    padding-bottom: 40px;
  }

  .applicant-bio{
    margin:0
  }

  .section-section{
    /* grid-gap: 20px 10px; */
    row-gap: 10px;
    column-gap: 100px;
  }

  .skillBox{
    padding: 7px 12px;
  }
}