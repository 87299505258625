.Kebab{
    display: flex;
    gap: 0.3em;
    flex-direction: column;
    padding: 6px 15px;
    cursor: pointer;
}

.Kebab > div{
    width: 0.3em;
    height: 0.3em;
    background-color: white;
    border-radius: 50%;
}


.KebabContainer{
    position: relative;
}


.DropdownMenu{
    position: absolute;
    right: 0;
    top: 100%;
    /* width: 100%; */
    width: 200px;
    /* font-size: 0.9em; */
    z-index: 20;
}
.DropdownMenu::before{
    box-shadow: 0px 4px 15px 0px #0000002b;
    content: "";
    top:0;
    left:0;
    background: white;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #0000001f;
    border-radius: 10px;
}

.DropdownMenuContent{
    position: relative;
    width: 100%;
    height: 100%;
    left:1px;
    top:1px;
    border-radius: 10px;
    overflow: hidden;
}

.DropdownMenuEnter::before{
    transform-origin: top;
    transform:scaleY(0.5);
    opacity: 0;
}
.DropdownMenuEnterActive::before{
    transform:scaleY(1);
    opacity: 1;
    transition: transform .1s ease-out, opacity 0.1s ease-in-out ;
}

.DropdownMenuEnter .DropdownMenuContent{
    opacity: 0;
}
.DropdownMenuEnterActive .DropdownMenuContent{
    opacity: 1;
    transition: opacity .05s ease-in-out;
}



.DropdownMenuExit::before{
    transform-origin: top;
    transform:scaleY(1);
    opacity: 1;
}
.DropdownMenuExitActive::before{
    transform:scaleY(0.5);
    opacity: 0;
    transition: transform .1s ease-out 0.05s, opacity 0.1s ease-in-out 0.05s ;
}

.DropdownMenuExit .DropdownMenuContent{
    opacity: 1;
}
.DropdownMenuExitActive .DropdownMenuContent{
    opacity: 0;
    transition: opacity .1s ease-in-out;
}


.Option{
    font-family: 'Poppins';
    color: grey;
    padding:12px 15px;
    cursor: pointer;
    display: flex;
    gap:10px;
    width: 100%;
    align-items: center;
    transition: background-color 0.05s ease-in-out;
}

.Option:hover{
    background: #eee;
}

.Option:active{
    background: #ddd;
}