.Slide{
    padding: 26px 31px;
    display: grid;
}

.TwoCol{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 62px;
    padding-right: 114px;
}

.Row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap:10px;
    margin-top:14px;
}

.ModalContent {
    background:white;
    padding-bottom:24px;
    border-radius: 0 0 14px 14px;
}

.ButtonTray{
    display: flex;
    justify-content: center;
    padding: 0 26px;
    gap:20px;
}