.SideBar{
    background: #2D3038;
    box-shadow: 3px -1px 49px rgb(0 0 0 / 49%);
    
}

.Logo{
    padding: 15px 30px;
    padding-top: 0px;
    /* width: 130px; */
    max-width: 190px;
}

.SideBarContent{
    position: sticky;
    top: 0px;
    padding: 60px 20px ;
    min-height: 100vh;
}

.NavList{ 
    margin-top: 30px;
    display: flex;
    flex-direction: column; 
    gap: 10px;
}

.NavItem{
    display: flex;
    color: #E0E0E0;
    border-radius: 38px;
    padding: 17px 30px;
    
}

.NavItemActive{
    background: linear-gradient(
        153deg
        , #1E2024 0.97%, rgba(45, 48, 56, 0) 54.5%)
    
}

.NavIcon{
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

.UserDetailsContainer {
    display: flex;
    gap:30px;
    padding: 20px;
    position: absolute;
    bottom: 40px;
}

.UserImg{
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    border: none;
    position: relative;
}

.UserImgContainer{
    position: relative;
}

.UserImgContainer::before{
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    background: linear-gradient(180deg, #1E2024 17.64%, rgba(45, 48, 56, 0) 98.59%);
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.UserDetails span{
    display: block;
    line-height: 1.2em;
}


.UserDetails .Title{
    font-size: 14px;
    font-weight: 100;
}

.UserDetails .Subtitle{
    font-size: 14px;
    font-weight: 400;
}