.QualInput{
    background: #F1F2F3;
    border-radius: 8px;
    padding: 18px;
    min-height: 300px;
}

.FirstRow{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap:10px;
    margin-top: 14px;
}

.SecondRow{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap:10px;

}