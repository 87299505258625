.CardContainer{
  position: relative;
  margin: 15px;
  padding-top: 32px;
}

.Card {
  display: flex;
  flex-direction: column;
  width: 363px;
  padding: 22px 18px;
  background: white;
  box-sizing: border-box;
  border-radius: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  grid-gap: 12px;
  gap: 12px;
  z-index: 10;
  position: relative;
  
}

.NewCard{
    border: 3.5px dashed #4A90E2;
    background: transparent;
    /* border-radius: 10px; */
    color: #4A90E2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.1s ease-in-out;
    min-height: 362px;
}

.NewCard:hover{
  transform:scale(1.01);
}

.NewCardContent{
  transition: transform 0.1s ease-in-out;
}
.NewCard:hover .NewCardContent
{
  transform: scale(1.1);
}
.NewCard:active .NewCardContent
{
  transform: scale(1.05);
}
.NewCard p {
    font-size: 18px;
}
.Plus{
    font-size: 60px;
}
.Card a{
    color: #232d4c;
}

.Container{
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(44px);
    border-radius: 15px;
    min-height: 50px;
    padding:15px;
    color: #232d4c;
    cursor: pointer;
    border: 2px solid #fff0;  
    transition: border-color 0.15s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out, background-color 0.1s ease-in-out;
}

.Container:hover.Active{
  background-color:#ffffff8c;
  /* border-color: #ffffff57;; */
  box-shadow: 1px 1px 5px #ffffff4f;
  transition: border-color 0.15s ease-in-out, transform 0.1s ease-in-out,  box-shadow 0.1s ease-in-out background-color 0.1s ease-in-out;
}

.Container:active.Active{
  transform:scale(0.98);
}


.SingleContainer{
    display: flex;
    align-items: center;
    /* cursor: pointer; */
}

.SingleContainer span {
    width: 100%;
}

.CardContentContainer {
  min-height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  position: relative;
}

.JobTitleDiv{
    display: flex;
}

.JobTitle {
  font-size: 28px;
  line-height: 33px;
  color: #232d4c;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.JobDetails {
  /* position: absolute; */
  /* width: 193px; */
  /* height: 17px; */
  /* left: 18px; */
  /* top: 56px; */
  /* font-family: SF Pro Display; */
  /* font-style: normal; */
  font-weight: 400;
  font-size: 14px;
  height: 34px;
  line-height: 17px;
  color: #232d4c;
  margin-top:4px;
}

.StudentsApplied {
  /* position: absolute;
  height: 18px;
  left: 18px;
  top: 98px; */
  /* font-family: SF Pro Display; */
  /* font-style: normal; */
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #232d4c;
  margin-top:8px;
  transition: opacity 0.15s ease-in-out;
}

.StudentsNumber {
  font-weight: bold;
  font-size: 44px;
  color: #4a90e2;
}

.Loading .StudentsNumber{
  color: transparent;
}
.BlueNextButton {
  /* position: absolute;
  width: 9px;
  height: 14px;
  right: 20px;
  bottom: 33px; */
  float: right;
}



.NewApplicants{
  position: absolute;
  padding: 10px;
  text-align: center;
  color: white;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 24px 24px 0px 0px;
  background: #FF5670;
  padding-bottom: 40px;
  transform: translateY(-4px);
  font-weight: 300;
}

.NewApplicantsAppear{
  transform: translateY(40px);
}

.NewApplicantsAppearActive{
  transform: translateY(-4px);
  transition: transform 0.5s cubic-bezier(0.22, 0.61, 0.24, 1.58);
}

.Loading{
  background: linear-gradient(90deg,#fff0, #ffffff52, #fff0);
  background-color: #ffffff42;
  content-visibility: hidden;
  height: 30px;
  border-radius: 5px;
  color:transparent;
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: -40px 0%;
  animation: loading 1s ease infinite;
  width: 50%;
}

@keyframes loading{
  to{
    background-position: right -40px top 0%;
  }
}

.Container img,
.Container span,
.StudentsApplied {
  transition: opacity 0.15s ease-in-out;
}

.Disabled.Container{
  cursor: default;
}

.Disabled img{
  display: none;
}

.CardContentContainer.Disabled .StudentsApplied{
  opacity: 0.5;
}

.Container.Disabled span{
  opacity: 0.5;
}

