.Button
{
    border-radius: 10px;
    font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif ;
    font-size: 16px;
    width: 100%;
    padding: 1em 2em;
    cursor: pointer;
    position: relative;
    outline: none;
    border: 1px solid #4A90E2;
    color:#4A90E2;
    background: white;
    box-shadow: 0px 0px 0px 0px #0001;
    transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.Primary 
{
    background: #4A90E2;
    color:white;
    border: none;
}

.Disabled{
    color:white;
    background: #D3D5DB;
    border: none;
    cursor: default;
}

.Button:hover {
    transform: scale(1.01);
    box-shadow: 0px 6px 8px 0px #0001;
}

.Button:active{
    filter: brightness(0.95);
    transform: scale(0.99);
}

.Button.Disabled:hover{
    transform: none;;
    box-shadow: none;
}

.Loading{
    cursor: default;
    outline: none;
}


.Loading::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: swipe infinite 1.5s ease-out;
    background: #0001;
    transform-origin: left;
}

@keyframes swipe {
    0% { transform: scaleX(0); opacity: 1;}
    70% {transform: scaleX(1); opacity: 1;}
    100%{ transform: scaleX(1); opacity: 0;}
}