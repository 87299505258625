@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');

*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

button{
  background-color: transparent;
  border: none;
}

.home-top-bar{
  padding-top: 45px;
  text-align: center;
}

.slider-button:hover{
  transform: scale(1.05);
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.25);
}


.slider-button:active{
  filter: brightness(0.95);
  transform: scale(0.99);
}

.slider-button:focus{
  outline: none;
}

.log-out{
  position:absolute;
  background: #FFFFFF;
  border: 1px solid #4A90E2;
  box-sizing: border-box;
  border-radius: 10px;
  width: 127px;
  height: 51px;
}

.job-postings{
  /* font-family: SF Pro Display; */
  margin-top: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 62px;
  text-align: center;
  color: #000000;
}

.middle-container{
  margin-top: 15px;
}

.slide-container{
  display: flex;
  align-items: center;
  max-width: 100%;
  /* justify-content: center; */
  overflow-y: hidden;

}

.single-item{
  text-align: center;
  cursor: pointer;
}

.items-container{
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin: auto;
}

.slide-container::-webkit-scrollbar{
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
.slide-container{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.slider-button{
  cursor: pointer;
  position: fixed;
  z-index: 50;
  background: #FFFFFF;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  width: 65px;
  height: 65px;
  text-align: center;
  vertical-align: center;
  align-items: center;
  transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}
@keyframes fade-in{
  from{opacity: 0; transform: scale(0.8);}
  to{opacity: 1; transform: scale(1);}
}

.right-arrow{
  right:50px;
}

.left-arrow{
  left:50px;
}








.bottom-container{
  text-align: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
}

.hidden{
  display: none;
}

.announcement-text{
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.25;
  background: linear-gradient(270deg, #44D7B6 0%, #3BB0EA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
}

.home-container{
  /* text-align: center; */
  width: 100vw;
  height: 100vh;
  background: radial-gradient(166.67% 269.75% at 85.98% 167.57%, #DCE8F6 0%, #FFFFFF 100%);
}

@media (max-width: 1200px){
  .announcement-text{
    font-size: 3.2rem;
  }
}

.central-loader {
  position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}


.button-enter{
  opacity: 0;
  transform: scale(0.7);
}
.button-enter-active{
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.1s ease-in-out, trasform 0.1s ease-in-out;
}

.button-exit
.button-exit:hover{
  opacity: 1;
  transform: scale(1);
}
.button-exit-active,
.button-exit-active:hover{
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 0.1s ease-in-out , transform 0.1s ease-in-out;
}

/* .button-exit-done{
  display: none;
} */